import "./styles.css";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from 'styled-components';

import tomatoImage from './../../imgs/WHAT-WE-SELL.jpg'; // Import the tomato image
import lettuceImage from './../../imgs/WHAT-WE-SELL-2.jpg'; // Import the lettuce image
import cheeseImage from './../../imgs/WHAT-WE-SELL-3.jpg'; // Import the cheese image

// { image: cheeseImage, label: "Copper Clove" },
export const allIngredients = [
  { image: tomatoImage, label: "ADC 12" },
  { image: lettuceImage, label: "Copper Ingots"},
 
  // Add more ingredients with their respective image paths
];

const Nav = styled(motion.nav)`
  background: transparent;
  padding: 5px 5px 0;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #eeeeee;
  
`;

const Li = styled(motion.li)`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: RGB( 202, 211, 215, 1);
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  padding: 10px 15px;
  position: relative;
  background: transparent;
  cursor: pointer;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  
  position: relative;
  user-select: none;

  &.selected {
    background: RGB( 33, 37, 41, 0.9);
  }
`;

const Ul = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  width: 100%;
`;

const Button = styled(motion.button)`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  width: 100%;
`;

const Main = styled(motion.main)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 128px;
  flex-grow: 1;
  user-select: none;
`;

const Window = styled(motion.div)`
  width: auto;
  min-width: 80%;
  max-width: 80%;
  height: 65vh;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(2px); 

  max-height: 65vh;
  border-radius: 10px;
  background-color: RGB( 33, 37, 41, 0.3);
  overflow: hidden;
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
  0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
  0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  display: flex;
  flex-direction: column;
  `;

export const SlideMenuCard = () => {
  const [selectedTab, setSelectedTab] = useState(allIngredients[0]);

  return (
    <Window>
      <Nav>
        <Ul>
          {allIngredients.map((item) => (
            <Li
              key={item.label}
              className={item === selectedTab ? "selected" : ""}
              onMouseEnter={() => setSelectedTab(item)}
            >
               {item.label}{/* Use <img> for the image */}
              {item === selectedTab ? (
                <motion.div className="underline" layoutId="underline" />
              ) : null}
            </Li>
          ))}
        </Ul>
      </Nav>
      <Main>
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedTab ? selectedTab.label : "empty"}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <img src={selectedTab ? selectedTab.image : ''} alt={selectedTab ? selectedTab.label : 'Empty'} />
          </motion.div>
        </AnimatePresence>
      </Main>
    </Window>
  );
};

export default SlideMenuCard;
