import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import NavbarEXSub from '../Navbar/Navbar_Expanded/NavbarEXSub';

// Define styles using Styled Components
const FormContainer = styled.div`
  width: 50vw;
  height: auto;
 
  padding: 20px;
  border: 1px solid #ccc;
  
  background-color: RGB( 33, 37, 41, 0.9);
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 2vh;
`;

const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: RGB( 202, 211, 215, 0.7);
  border-radius: 10px;
  justify-content: center;
  padding: 10px;
  margin: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: RGB(255,255,255, 1);
  color: white;
  font-size: 18px;
  font-family: 'Nunito Sans', sans-serif;
`;

const Label = styled.label`
  color: white;
  
  width: auto;
  font-size: 25px;
  font-family: 'Urbanist', sans-serif;
`;

const TA = styled.textarea`
padding: 10px;
margin: 10px;
border: 1px solid #ccc;
border-radius: 5px;
background-color: RGB(255,255,255, 1);
color: white;
font-size: 18px;
font-family: 'Nunito Sans', sans-serif;
`;



const SubmitButton = styled(motion.button)`
  width: auto;
  position: relative;
  height: auto;
  background-color: transparent;
  
  border: none;
  cursor: pointer;
`;

const ContactForm = () => {
    // Define animation properties
    const containerVariants = {
        hidden: { opacity: 0, y: -100 },
        visible: { opacity: 1, y: 0 },
    };

    const buttonVariants = {
        hover: { scale: 1 },
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            transition={{ duration: 0.5 }}
        >
            <FormContainer>
                <Form>
                    <Container>
                        <Label htmlFor="name">Name:</Label>
                        <Input placeholder="What's your name?" type="text" id="name" name="name" />
                    </Container>

                    <Container>
                        <Label htmlFor="email">Email:</Label>
                        <Input placeholder="Your email address?" type="email" id="email" name="email" />
                    </Container>
                    <Container>
                        <Label htmlFor="number">Number:</Label>
                        <Input placeholder="Your contact number?" type="email" id="email" name="email" />
                    </Container>
                    <Container>
                        <Label htmlFor="website">Website:</Label>
                        <Input placeholder="Your website?" type="email" id="email" name="email" />
                    </Container>
                    <Container>
                        <Label htmlFor="message">Message:</Label>
                        <TA placeholder="Any comments?" id="message" name="message" rows="4" />
                    </Container>
                    <SubmitButton
                        type="submit"
                        variants={buttonVariants}
                        whileHover="hover"
                    >
                        <NavbarEXSub title ={'Reach out'}></NavbarEXSub>
                       
                    </SubmitButton>
                </Form>
            </FormContainer>
        </motion.div>
    );
};

export default ContactForm;
