import { useState, useRef, useEffect } from "react";
import { useAnimate, stagger, motion } from "framer-motion";
import styled from "styled-components";
import DeviceInfo from './../../utility/WindowSize/DeviceInfo'
import NavbarEXHeader from "./NavbarEXHeader";
const staggerMenuItems = stagger(0.01, { startDelay: 0 });

function useMenuAnimation(isOpen, sideWays) {
    const [scope, animate] = useAnimate();


    useEffect(() => {
        const animationPromises = [];
        ////console.log("ISopen, DropDown Stagger: ", isOpen)

        animationPromises.push(
            animate(
                "ul",
                {
                    clipPath: isOpen
                        ? "inset(0% 0% 0% 0% round 10px)"
                        : "inset(0% 0% 0% 0% round 10px)"
                },
                {
                    type: "spring",
                    bounce: 0,
                    duration: 0.1
                }
            )
        );


        //console.log("S",sideWays)
        if (sideWays == true) {
            const liAnimation = animate(
                "li",
                isOpen
                    ? { opacity: 1, scaleX: 1, scaleY: 1, filter: "blur(0px)" }
                    : { opacity: 0, scaleX: 2, scaleY: 1, filter: "blur(0px)" },
                {
                    duration: isOpen ? 0.2 : 0,
                    delay: isOpen ? staggerMenuItems : 0
                }
            );
            animationPromises.push(liAnimation);
        } else {


            const liAnimation = animate(
                "li",
                isOpen
                    ? { opacity: 1, scaleY: 1, scaleX: 1, filter: "blur(0px)" }
                    : { opacity: 0, scaleY: 0.1, scaleX: 1, filter: "blur(0px)" },
                {
                    duration: isOpen ? 0.2 : 0,
                    delay: isOpen ? staggerMenuItems : 0
                }
            );
            animationPromises.push(liAnimation);
        }


        return () => {
            // Cancel the ongoing animations
            animationPromises.forEach(promise => promise.cancel());
        };
    }, [isOpen]);



    return scope;
}

// filter: drop-shadow(4px 0px #CAD3D7);
const Navigation = styled.nav`
  filter: drop-shadow(4px 0px #CAD3D7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

//margin-top: ${({ navbarheight }) => navbarheight};
const StyledDropDownStagger = styled.div`
margin-top: 5vh;
  -webkit-font-smoothing: antialiased;
  display: flex;
 /* Center its children vertically */
  position: fixed;
  width: 100vw;
  z-index: 1001;
  height: ${({ height }) => height ? 'auto' : '100vh'};
  visibility:  ${({ visibility }) => visibility};
  
`;

//border: 3px solid white;
const StaggeredUl = styled.ul`
  display: flex;
  flex-direction: column;
    
  background: transparent;
  margin-bottom: 10px;
  padding: 0; /* Remove default padding */
  height: 100%; /* Adjust height as needed */
  width: auto;
  
  
`;

const StaggeredUlM = styled.ul`
  display: flex;
  flex-direction: column;
  
  background: transparent;
  margin-bottom: 10px;
  padding: 0; /* Remove default padding */
  height: 100%; /* Adjust height as needed */
  width: 100%;
  
`;

//border: 3px solid orange;
const ItemContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: center;

`;

// transform-origin: -20px 50%;
//border: 3px solid red;
const StaggeredLi = styled.li`
  width: 30vw;
  color: white;
  
  height: auto;
  list-style-type: none;
  font-size: 20px;
  transform-origin: center top;
  
  
`;

const StaggeredLiSub = styled.li`
  width: auto;
  color: white;
  min-width: 70%;
  max-width: 70%;
  
  list-style-type: none;
  font-size: 20px;
  transform-origin: left center;
  
  
`;

const StaggeredLiSubM = styled.li`
  width: auto;
  color: white;
  min-width: 70%;
  max-width: 70%;
  
  list-style-type: none;
  font-size: 20px;
  transform-origin: center top;
  
  
`;


const DropDownStagger = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const scope = useMenuAnimation(isOpen, props.sideWays);
    const [items, setItems] = useState(["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"]);

    const [duration, setDuration] = useState(DeviceInfo.FPS_MODE_0_T);
    const [timeoutId, setTimeoutId] = useState(null); // Step 1


    useEffect(() => {
     
    }, [props.sideWays]);

    useEffect(() => {
        let fpsTiming = props.fpsTiming
        if (fpsTiming > 0) {
            setDuration(fpsTiming)
        }
    }, [props.fpsTiming]);

    useEffect(() => {
        //console.log("Stagger Toggle: ", props.toggleNavbar)
        if (props.toggleNavbar == true) {
            delay(duration * 0.6);
        } else {
            setIsOpen(false);
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        }
        //console.log(props.toggleNavbar)
        //setIsOpen(props.toggleNavbar);
    }, [props.toggleNavbar]);

    useEffect(() => {
        //console.log("DDSTAG", props.items)
        if (props.items != null) {
            setItems(props.items)

        }

    }, [props.items]);

    const [SeperatedItems, setSeperatedItems] = useState([['test'], ['test'], ['test']])
    // ... (other state variables)

    useEffect(() => {
        const isEvenLength = items.length % 3 === 0;
        const thirdItemsLength = Math.floor(items.length / 3);
        const firstThird = [];
        const secondThird = [];
        const thirdThird = [];
      
        if (isEvenLength) {
          for (let i = 0; i < thirdItemsLength; i++) {
            if (items[i].key !== "back") {
              firstThird.push(items[i]);
            }
          }
      
          for (let i = thirdItemsLength; i < 2 * thirdItemsLength; i++) {
            if (items[i].key !== "back") {
              secondThird.push(items[i]);
            }
          }
      
          for (let i = 2 * thirdItemsLength; i < items.length; i++) {
            if (items[i].key !== "back") {
              thirdThird.push(items[i]);
            }
          }
        } else {
          for (let i = 0; i <= thirdItemsLength; i++) {
            if (items[i].key !== "back") {
              firstThird.push(items[i]);
            }
          }
      
          for (let i = thirdItemsLength + 1; i <= 2 * thirdItemsLength; i++) {
            if (items[i].key !== "back") {
              secondThird.push(items[i]);
            }
          }
      
          for (let i = 2 * thirdItemsLength + 1; i < items.length; i++) {
            if (items[i].key !== "back") {
              thirdThird.push(items[i]);
            }
          }
        }
      
        setSeperatedItems([firstThird, secondThird, thirdThird]);
      }, [items]);

    useEffect(() => {
        //console.log(SeperatedItems[0], SeperatedItems[1]);
    }, [SeperatedItems]);

    const delay = (seconds) => {
        const id = setTimeout(() => {
            setIsOpen(props.toggleNavbar);
        }, seconds * 1000);
        setTimeoutId(id)
    };

    const dropdownListRef = useRef(null); // Create a ref for the DropdownList
    const [dropdownListHeight, setDropdownListHeight] = useState(66 * 9);

    /*
    useEffect(() => {
        if (dropdownListRef.current && dropdownListRef.current.clientHeight) {
            setDropdownListHeight(dropdownListRef.current.clientHeight); // Set the height when the component mounts
            ////console.log("dropDownHeight 1: ", dropdownListRef.current.clientHeight)
            
        }

    }, [dropdownListRef.current]);
    */
    useEffect(() => {
        const updateDropdownListHeight = () => {
            if (dropdownListRef.current) {
                setDropdownListHeight(dropdownListRef.current.clientHeight + parseFloat(props.navbarHeight));
            }
        };

        // Call the function once when the component mounts
        updateDropdownListHeight();

        // Attach an event listener or observer to update the height when it changes
        const observer = new ResizeObserver(updateDropdownListHeight);
        if (dropdownListRef.current) {
            observer.observe(dropdownListRef.current);
        }

        // Clean up the observer when the component unmounts
        return () => {
            observer.disconnect();
        };
    }, [props.navbarHeight]); // No dependencies, so it runs once on mount


    //{console.log(`DropDownStagger instance: idx=${props.idx}, customKey=${props.customKey}`)}


    useEffect(() => {
        //console.log("DropDownHeight Stagger: ", dropdownListHeight, parseFloat(props.navbarHeight), dropdownListHeight + parseFloat(props.navbarHeight))
        props.setDDHeight(dropdownListHeight, props.idx)


    }, [dropdownListHeight]);


    {
        return (
            <>

                <StyledDropDownStagger navbarheight={props.navbarHeight} ref={dropdownListRef} height={props.deviceMode != 'mobile'} visibility={isOpen ? "visible" : "hidden"} >

                    <Navigation
                        ref={scope}
                        style={{
                            width: '100%'
                        }}
                    >

                        {(props.deviceMode != 'mobile' ? (
                            <>
                                <StaggeredUl
                                    style={{
                                        pointerEvents: isOpen ? "auto" : "none",
                                        clipPath: "inset(10% 50% 90% 50% round 10px)",

                                    }}
                                >
                                    {(SeperatedItems[0].map((item, index) => (
                                        <ItemContainer key={"itemContainer_" + index}>

                                            <StaggeredLi key={"itemStagger_" + index}>{item}</StaggeredLi>
                                        </ItemContainer>


                                    )))}

                                </StaggeredUl>

                                <StaggeredUl
                                    style={{
                                        pointerEvents: isOpen ? "auto" : "none",
                                        clipPath: "inset(10% 50% 90% 50% round 10px)"
                                    }}
                                >
                                    {(SeperatedItems[2].map((item, index) => (
                                        <ItemContainer key={"itemContainer2_" + index}>

                                            <StaggeredLi key={"itemStagger2_" + index}>{item}</StaggeredLi>
                                        </ItemContainer>


                                    )))}

                                </StaggeredUl>

                                <StaggeredUl
                                    style={{
                                        pointerEvents: isOpen ? "auto" : "none",
                                        clipPath: "inset(10% 50% 90% 50% round 10px)"
                                    }}
                                >
                                    {(SeperatedItems[1].map((item, index) => (
                                        <ItemContainer key={"itemContainer2_" + index}>

                                            <StaggeredLi key={"itemStagger2_" + index}>{item}</StaggeredLi>
                                        </ItemContainer>


                                    )))}

                                </StaggeredUl>


                            </>
                        ) :
                            <>

                                {(props.sideWays ? (

                                    <StaggeredUlM
                                        style={{
                                            pointerEvents: isOpen ? "auto" : "none",
                                            clipPath: "inset(10% 50% 90% 50% round 10px)"

                                        }}
                                    >
                                        {(items.map((item, index) => (
                                            <ItemContainer key={"itemContainerMobile_" + index}>

                                                <StaggeredLiSub key={"itemStaggerMobile_" + index}>{items[index]}</StaggeredLiSub>
                                            </ItemContainer>
                                        )))}
                                    </StaggeredUlM>

                                ) : (
                                    <StaggeredUlM
                                        style={{
                                            pointerEvents: isOpen ? "auto" : "none",
                                            clipPath: "inset(10% 50% 90% 50% round 10px)"

                                        }}
                                    >
                                        {(items.map((item, index) => (
                                            <ItemContainer key={"itemContainerMobile2_" + index}>

                                                <StaggeredLiSubM key={"itemStaggerMobile2_" + index}>{items[index]}</StaggeredLiSubM>
                                            </ItemContainer>


                                        )))}
                                    </StaggeredUlM>

                                ))}
                            </>

                        )}

                    </Navigation>
                </StyledDropDownStagger>
            </>
        );
    };
}

export default DropDownStagger;

/*

 headerComponents.push(<NavbarNEXHeader
      key={"NavEXHEAD_" + -1}
      showDropdown={navbarToggle}
      title={'Back'}
      link={'/fay'}
      setState={handleCurrentHeaderSub}
      state={-1 + 0.5}
    />)

<div
                key={"SpacingBox2"}
                style={{ height: props.navbarHeight, display: 'block' }}
            ></div>

const Button = styled(motion.button)`
  -webkit-appearance: button;
  background: var(--accent);
  color: var(--background);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  path {
    fill: var(--background);
  }
  visibility: hidden;
  position: fixed;
`;

<Button whileTap={{ scale: 0.97 }}>
    Menu
    <div className="arrow" style={{ transformOrigin: "50% 55%" }}>
        <svg width="15" height="15" viewBox="0 0 20 20">
            <path d="M0 7 L 20 7 L 10 16" />
        </svg>
    </div>
</Button>
*/