import React, { Component, useEffect } from 'react';


export class DeviceInfo  {

    static MOBILE_DEVICE_TYPE ='mobile'
    static DESKTOP_DEVICE_TYPE ='desktop'
    static TABLET_DEVICE_TYPE ='tablet'

    static FPS_MODE_0 = 0;
    static FPS_MODE_30 = 30;
    static FPS_MODE_60 = 60;
    static FPS_MODE_120 = 120;
    static FPS_MODE_160 = 160; // Fixed typo here
    static FPS_MODE_240 = 240;

    static FPS_MODE_0_T = 0.4;
    static FPS_MODE_30_T = 0.4;
    static FPS_MODE_60_T = 0.75;
    static FPS_MODE_120_T = 0.25;
    static FPS_MODE_160_T = 0.25; // Fixed typo here
    static FPS_MODE_240_T = 0.4;

    static FPS_MODE_0_TM = 0.4;
    static FPS_MODE_30_TM = 0.4;
    static FPS_MODE_60_TM = 0.75;
    static FPS_MODE_120_TM = 0.4;
    static FPS_MODE_160_TM = 0.4; // Fixed typo here
    static FPS_MODE_240_TM = 0.4;

    static FPS_MODES = [
        DeviceInfo.FPS_MODE_0, // Use the class name to access static properties
        DeviceInfo.FPS_MODE_30,
        DeviceInfo.FPS_MODE_60,
        DeviceInfo.FPS_MODE_120,
        DeviceInfo.FPS_MODE_160,
        DeviceInfo.FPS_MODE_240
    ];

    static FPS_MODES_TIMING = {
        [DeviceInfo.FPS_MODE_0]: DeviceInfo.FPS_MODE_0_T,
        [DeviceInfo.FPS_MODE_30]: DeviceInfo.FPS_MODE_30_T,
        [DeviceInfo.FPS_MODE_60]: DeviceInfo.FPS_MODE_60_T,
        [DeviceInfo.FPS_MODE_120]: DeviceInfo.FPS_MODE_120_T,
        [DeviceInfo.FPS_MODE_160]: DeviceInfo.FPS_MODE_160_T,
        [DeviceInfo.FPS_MODE_240]: DeviceInfo.FPS_MODE_240_T
    };

    static FPS_MODES_TIMING_MOBILE = {
        [DeviceInfo.FPS_MODE_0]: DeviceInfo.FPS_MODE_0_TM,
        [DeviceInfo.FPS_MODE_30]: DeviceInfo.FPS_MODE_30_TM,
        [DeviceInfo.FPS_MODE_60]: DeviceInfo.FPS_MODE_60_TM,
        [DeviceInfo.FPS_MODE_120]: DeviceInfo.FPS_MODE_120_TM,
        [DeviceInfo.FPS_MODE_160]: DeviceInfo.FPS_MODE_160_TM,
        [DeviceInfo.FPS_MODE_240]: DeviceInfo.FPS_MODE_240_TM
    };

    constructor(callBackHandler, IPHandler) {
        
        this._isMounted = this.componentDidMount();
        this.width = window.innerWidth;
        this.height= window.innerHeight;
        this.deviceType=  this.getDeviceType();
        this.actualDeviceType= this.getActualDeviceType();
        this.ipAddress= this.fetchIpAddress();
        this.fpsMode=  DeviceInfo.FPS_MODE_120;
        this.updateCallback = callBackHandler;
        this.updateIPCallback = IPHandler;
    }

    componentDidMount() {
        this._isMounted = true; // Component is mounted
        this.fetchIpAddress();
        window.addEventListener('resize', this.handleResize);
        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getDeviceType() {
        if (window.innerWidth < 768) {
            return DeviceInfo.MOBILE_DEVICE_TYPE;
        } else if (window.innerWidth < 1024) {
            return DeviceInfo.TABLET_DEVICE_TYPE;
        } else {
            return DeviceInfo.DESKTOP_DEVICE_TYPE;
        }
    }
    
    handleResize = () => {
        this.width = window.innerWidth;
        this.height= window.innerHeight;
        this.deviceType= this.getDeviceType();
        this.actualDeviceType= this.getActualDeviceType();
        this.fpsTiming = this.getFPSTiming(this.fpsMode)

        this.updateCallback({
            width: this.width,
            height: this.height,
            deviceType: this.deviceType,
            actualDeviceType: this.actualDeviceType,
            fpsMode: this.fpsMode,
            fpsTiming: this.fpsTiming
        })
        
    };

    getActualDeviceType() {
        const userAgent = navigator.userAgent.toLowerCase();


        //console.log(userAgent)
        return userAgent;

        if (userAgent.match(/mobile|android|iphone|ipad|ipod|blackberry|opera mini|iemobile|wpdesktop/i)) {
            return 'Mobile';
        } else if (userAgent.match(/tablet|ipad/i)) {
            return 'Tablet';
        } else {
            return 'Desktop';
        }
    }

    setFPSMode = (fps, handleDeviceInfo) => {
        

        if (true) {
            if (typeof fps === 'number') {
                for (let i = DeviceInfo.FPS_MODES.length - 1; i >= 0; i--) {
                    if (fps >= DeviceInfo.FPS_MODES[i] && this.fpsMode != DeviceInfo.FPS_MODES[i]) {
                        this.fpsMode = DeviceInfo.FPS_MODES[i];
                        //console.log("DEVINFO, FPS: ", DeviceInfo.FPS_MODES, DeviceInfo.FPS_MODES[i]);
                        this.handleResize()
                        
                        break; // Exit the loop once the appropriate fpsMode is found
                    }
                }
            }
        }
    }

    getFPSTiming = () => {

        if(this.deviceType == 'Desktop'){
            return DeviceInfo.FPS_MODES_TIMING[this.fpsMode]
        } else {
            return DeviceInfo.FPS_MODES_TIMING_MOBILE[this.fpsMode]
        }
        

    }


    async fetchIpAddress() {
        
        try {
            const response = await fetch('https://api64.ipify.org?format=json');
            const data = await response.json();
            this.ipAddress = data.ip.toString(); // Convert IP address to string
            this.updateIPCallback(this.ipAddress)
        } catch (error) {
            //console.error('Error fetching IP address:', error);
            this.ipAddress = ''; // Set a default value as an empty string
        }
    }
    


}



export const DeviceInfoDisplay = ({ width, height, deviceType, fpsMode, fpsTiming, actualDeviceType, ipAddress }) => {
    return (
      <div style={{ position: 'relative', height: 'auto', width: '100vw', backgroundColor: 'black', color: 'white', top: '100px', left: '0', zIndex: 100, fontSize:'11pt' }}>
        <h1>Screen Information</h1>
        <p>Width: {width}px</p>
        <p>Height: {height}px</p>
        <p>Estimated Device Type: {deviceType}</p>
        <p>FPS Mode: {fpsMode}</p>
        <p>FPS Timing: {fpsTiming}</p>
        <p>Actual Device Type: {actualDeviceType}</p>
        <p>IP Address: {ipAddress}</p>
      </div>
    );
  };
  




export default DeviceInfo;


