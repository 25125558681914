import { motion } from "framer-motion";
import styled from "styled-components";
import React, { useState, useEffect } from "react"; // Import useState
import ProductsIcon from './../../../imgs/precision_manufacturing_white_48dp.svg'
import PurchasingIcon from './../../../imgs/currency_exchange_white_48dp.svg'
import AboutIcon from './../../../imgs/info_white_48dp.svg'
import ContactIcon from './../../../imgs/email_white_48dp.svg'

const Container = styled(motion.div)`
  width: auto;
  height: auto;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.5s ease;
 
  z-index: 1010;
`;

const LinkS = styled("div")`
  text-decoration: none !important;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const LoadedImage = styled(motion.img)`
    width: 3vw;
    
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;


const Title = styled.div`
  width: 100%;
  
  text-align: center;
  margin-left: 5px; /* Add margin to create space between image and title */
`;

const animateVariantsLR = {
  visible: {

    scaleX: 1,
    transition: {
      duration: 0.2, // Adjust the duration as needed
    },

  },
  hidden: {

    scaleX: 0,
    transition: {
      duration: 0.2, // Adjust the duration as needed
    },
  },
};

const BottomLine = styled(motion.div)`
z-index: 1011;

`;


export const NavbarEXHeader = (props) => {



  const [fontColor, setFontColor] = useState("white"); // Initialize fontColor state
  const [showArrow, setShowArrow] = useState(false);



  const handleHoverEnter = () => {
    setFontColor("black"); // Update fontColor state
    props.setState(props.state);
    setShowArrow(true)
  };

  const handleHoverLeave = () => {
    setFontColor("white"); // Reset fontColor state
    props.setState(null);
    setShowArrow(false)
  };

  useEffect(() => {
    //console.log(props.currentState, props.state)
  }, [props.currentState]);


  const handleClick = () => {
    window.location.href = props.link; // Navigate to the link
  };

  return (
    <Container
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      onClick={handleClick}
      to={props.link}
      isHovered={props.isHovered}
      key={props.title + "HEADER"}
      layout
      whileHover={{
        scale: 1.4,
        color: "#CAD3D7",
        transition: { duration: 0.3 },
        
      }}
      animate={{
        color: fontColor,
      }}
      transition={{ duration: 0.4 }}
      whileTap={{ scale: 0.8 }}
    >
      {(props.title == 'Products' && false && <>
        <LoadedImage src={ProductsIcon} alt="Icon" />
      </>)}

      {(props.title == 'Purchasing' && false && <>
        <LoadedImage src={PurchasingIcon} alt="Icon" />
      </>)}

      {(props.title == 'About' && false && <>
        <LoadedImage src={AboutIcon} alt="Icon" />
      </>)}

      {(props.title == 'Contact' && false && <>
        <LoadedImage src={ContactIcon} alt="Icon" />
      </>)}





      <Title>{props.title}</Title>

      
    </Container>
  );
};

export default NavbarEXHeader;

/*
<BottomLine
          style={{
            transformOrigin: "left center",
            width: "100%",
            backgroundColor: "white",
            position: "fixed",
            bottom: "-1px",
            height: "4px",
          }}
          variants={animateVariantsLR}
          animate={showArrow ? "visible" : "hidden"}
        ></BottomLine>
        */