import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const LoadingScreenWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const LoadingText = styled.p`
  color: white;
  font-size: 24px;
`;

const SvgWrapper = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
`;

function LoadingScreen({ isLoaded, isLoading, loadingText, handleLoaded }) {
    //const svgPath = `M10 50 L30 80 L90 20`;
    //const svgPath = `M20 80 L40 20 M40 20 L60 80 M60 80 L80 20`;
    const svgPath = `M50 10
                   A 40 40 0 1 1 50 90
                   A 40 40 0 1 1 50 10`;
    return (
        <AnimatePresence>
            {isLoading && (
                <LoadingScreenWrapper
                    initial={{ opacity: 1, scale: 1, rotate: 0 }}
                    animate={{ opacity: 1, scale: 1, rotate: 0 }}
                    exit={{ opacity: 0, }}
                    transition={{ duration: 0.5 }}
                >
                    <LoadingText>{loadingText}</LoadingText>
                    {isLoaded && (
                        <SvgWrapper viewBox="0 0 100 100">
                            <motion.path
                                fill="none"
                                strokeWidth="5"
                                stroke="white"
                                d={svgPath}
                                initial={{ pathLength: 0, opacity: 0 }}
                                animate={{ pathLength: 1, opacity: 1 }}
                                transition={{ duration: 1.5 }}
                                onAnimationComplete={handleLoaded}
                            />
                        </SvgWrapper>
                    )}
                </LoadingScreenWrapper>
            )}
        </AnimatePresence>
    );
}

export default LoadingScreen;
