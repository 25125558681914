import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ThreeJSApp from '../utility/Animations/ThreeJS/TJS_Utilities';
import Logo from '../Icons/LogoTitle';
import NM_DD from './../Navbar/Navbar_Mobile/NM_DD'

import AlImage from './../../imgs/WHAT-WE-SELL.jpg'
import CopperImage from './../../imgs/WHAT-WE-SELL-2.jpg'
import CloveImage from './../../imgs/WHAT-WE-SELL-3.jpg'

import SmelterImage from './../../imgs/SMELTER.jpg'
import Smelter2Image from './../../imgs/SMELTER-2.jpg'
import Smelter3Image from './../../imgs/SMELTER-5.jpg'

import Footer from './../Footer/Footer';
import Carousel from '../ImageCarousel/Carousel';
import SlideMenuCard from '../SlideDisplayCard/SlideDisplayCard';



import ContactForm from '../FormContainer/FormContainer';
//#040e3d background-color: #CAD3D7;
const Container = styled(motion.div)`
  display: flex;

  position: relative;
  font-family: "Urbanist", sans-serif;
  flex-direction: column;
  align-items: center;
  

  margin: auto;
  padding-top: 5vh;
  padding-bottom: 0vh;

  min-height: 100%;
  height: auto;
  max-height: 200%;


  width: 100%;
  max-width: 100%;

  
  z-index: 1;
  
`;

const Title = styled.div`
  max-width: 80%;
  height: auto;
    margin-top: 2vh;
  font-size: 4rem;
  color: white;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  
  border-bottom: 1px solid black;
`;

const Card = styled.div`

font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: column;
align-items: center;
justify-content: auto;

margin-top: 2vh;


height: auto;

width: auto;

color: white;

backdrop-filter: blur(10px);

border-left: 0;

border-right: 0;
`;

const ImageCard = styled.div`
position: relative;
z-index: 1;
font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: auto;
align-items: center;
justify-content: space-evenly;

height: auto;
width: 100%;


background-color: RGB(255,255,255, 1);
backdrop-filter: blur(10px);

margin: 0;
padding-top: 10px;
padding-bottom: 10px;

overflow: hidden;
    
`;



const AnimatedDescription = styled(motion.p)`
  font-size: 2rem;
  max-width: 70%;
  
  font-family: 'Nunito Sans', sans-serif;
  color: #36A423;
`;

const TextCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  
  margin-top: 1vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 1vh;

`;

const TextCardWrapper = styled.div`
  border-top: 3px solid white;
  
  margin-top: 4vh;
  width: auto;
  height: auto;
 

`;

const TextCardB = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

//font-family: 'Nunito Sans', sans-serif;
//font-family: poppins,Sans-serif;

const TextCardChild = styled.div`
  margin: auto;
  color: white;
  font-size: 1.3rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;



const TextCardChildE = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildC = styled.div`
  margin: 0;
  color: white;
  max-width: 30vw;
  margin-left: 20px;
  text-align: left;
`;

const TextCardChildD = styled.div`
  margin: 0;
  color: white;
  max-width: 20vw;
  margin-left: 20px;
  text-align: left;
  
`;


const TextCardChildA = styled.div`
  margin: 0;
  color: white;
  font-size: 5rem;
  margin: 20px;
  text-align: left;
    
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  
`;

const TextCardChildB = styled.div`
  margin: 0;
  color: white;
  font-size: 2rem;
  width: auto;
  
  text-align: left;
`;

const LoadedImage = styled(motion.img)`
    
    
    max-width: 33%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

const contactInfo = {
  ROC: {
    address: "ZIFA Road, ZIYA Green Industrial Park, JingHai, TianJin, China",
    tel: "+86-10-85885868"
  },
  EUR: {
    address: "Rivium Quadrant 90, 2909 LC, Capelle A/D Ijssel, Netherlands",
    tel: "+31-10-2543381"
  },
  HKG: {
    address: "Room C, 20F, Lucky Plaza, 315-321 Lockhart RD, Wanchai, HongKong",
    tel: "+852-25592383"
  },
  MYS: {
    address: "NO. 286 Jalau Pulau Carey, 42500 Telok Panglima Galay, Selangor, Malaysia",
    tel: "+60-11243462133"
  },
};

// Framer Motion variants
const containerVariants = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.3 } },
};

const buttonVariants = {
  hover: { scale: 1.05 },
};

const Contact = (props) => {


  useEffect(() => {
    // ...
  }, [props.isLoading]);

  const word = "Recycled ingot production at it's finest";

  const animateDescription = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const highlightVariants = {
    hidden: { opacity: 1, color: 'Black' },
    visible: { opacity: 1, color: '#36A423' },
  };


  const images = [
    SmelterImage,
    Smelter2Image,
    Smelter3Image,
    // Add more image URLs here
  ];

  const ncuGroupInfo = `
    NCU Group has more than 20 years of expertise working with non-ferrous metal scrap.

    NCU utilizes cutting-edge flotation technology for processing, screening, and smelting to cleanly produce pure non-ferrous metals suitable for manufacturing. 
  `;


  //We primarily import stainless steel and non-ferrous metal scraps from the US, Europe, and Japan. Currently, per month we can produce 3000MT of aluminium, and 2000MT of copper.
  const ncuGroupTitle = `
    Crafting. Precision. Excellence.
    `;

  const words = ncuGroupInfo.split(/\s+/);

  // Group words into arrays of 5 words each
  const groupedWords = [];
  for (let i = 0; i < words.length; i += 6) {
    groupedWords.push(words.slice(i, i + 5).join(' '));
  }

  return (
    <Container variants={containerVariants} initial="initial" animate={!props.isLoading ? "animate" : "initial"}>



      <Title> Contact</Title>
      <TextCard>



        <TextCardChildA >
          <TextCardChildB>
            Business Line, Operating Locations, & Email.
          </TextCardChildB>
        </TextCardChildA >
      </TextCard>

      <TextCard>
        <ContactForm></ContactForm>
      </TextCard>


      <Footer></Footer>

    </Container>
  );


};




export default Contact;


/*
<TextCardWrapper>
        <TextCard>
          <TextCardChildA >
            <TextCardChildB>
              Email
            </TextCardChildB>
          </TextCardChildA >
          <TextCardChild >
            <TextCardChildD> info@ncugroup.com</TextCardChildD>

          </TextCardChild >
        </TextCard>



        <TextCard>
          <TextCardChildA >
            <TextCardChildB id={'Products-AL'}>
              China
            </TextCardChildB>

          </TextCardChildA >
          <TextCardChild >
            <TextCardChildD>{contactInfo['ROC']['tel']}</TextCardChildD>
            <TextCardChildD>{contactInfo['ROC']['address']}</TextCardChildD>
          </TextCardChild >

          <TextCardChildA >
            <TextCardChildB>
              Europe
            </TextCardChildB>
          </TextCardChildA >
          <TextCardChild >
            <TextCardChildD>{contactInfo['EUR']['tel']}</TextCardChildD>
            <TextCardChildD>{contactInfo['EUR']['address']}</TextCardChildD>
          </TextCardChild >

        </TextCard>

        <TextCard>
          <TextCardChildA >
            <TextCardChildB>
              Hong
            </TextCardChildB>
            <TextCardChildB>
              Kong
            </TextCardChildB>
          </TextCardChildA >
          <TextCardChild >
            <TextCardChildD>{contactInfo['HKG']['tel']}</TextCardChildD>
            <TextCardChildD>{contactInfo['HKG']['address']}</TextCardChildD>

          </TextCardChild >

          <TextCardChildA >
            <TextCardChildB>
              Malaysia
            </TextCardChildB>
          </TextCardChildA >
          <TextCardChild >
            <TextCardChildD>{contactInfo['MYS']['tel']}</TextCardChildD>
            <TextCardChildD>{contactInfo['MYS']['address']}</TextCardChildD>
          </TextCardChild >
        </TextCard>
      </TextCardWrapper>
*/