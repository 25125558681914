import React, { useState, useRef, useEffect } from "react";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import styled from "styled-components";
import { Link, NavLink, useLocation } from 'react-router-dom';

import backIgm from './../../../imgs/arrow_back_ios_white_48dp.svg'
import forIgm from './../../../imgs/arrow_forward_ios_white_48dp.svg'
import LineAnimation from "../../utility/Animations/LineAnimation";

const DropdownItem = styled(motion.div)`
    margin-bottom: 2px;
    min-width:50vw;
    height:2vh;
    color:white;
    font-size: 20px;
    font-family: "Urbanist", sans-serif;
    justify-content: left;
    display: flex;
    align-items: center;

`;

const Title = styled(motion.div)`

max-width:50%;
height:100%;


`;

const ContainerArrow = styled(motion.div)`
 
`;

const Container = styled(motion.div)`
  
`;
const SvgWrapper = styled(motion.svg)` /* Changed to motion.svg */
  width: auto;
  height: 20px;
`;

const ArrowPath = styled(motion.path)` /* New styled component for the arrow */
  fill: none;
  stroke-width: 5;
  stroke: white;
`;

const LoadedImage = styled(motion.img)`
    width: 100%;
    background-color: transparent;
    z-index: 1011;
    display: flex;

    max-height: 5vh; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
    display: 'flex;
`;


const LoadedImageRev = styled(motion.img)`
    width: auto;
    background-color: transparent;
    z-index: 1011;
    display: flex;
    
    max-height: 18px; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
    display: 'flex;
    
`;


const BottomLine = styled(motion.div)`
z-index: 1011;

`;




export const NavbarEXSub = (props) => {

  const [fontColor, setFontColor] = useState("#CAD3D7");
  const [showArrow, setShowArrow] = useState(false);

  const handleClick = () => {
    //window.alert(props.link)
    window.location.href = props.link; // Navigate to the link
  };

  const handleHoverEnter = () => {
    setFontColor("black");
    setShowArrow(true);

    //props.setState(props.state);
  };

  useEffect(() => {
    //console.log(props)
  }, [props.link]);

  const handleClickEnter = () => {
    setShowArrow(true);
    handleClick();
    props.setState(props.state);
  };

  const handleHoverLeave = () => {
    setFontColor("#CAD3D7");
    setShowArrow(false);
    //props.setState(null);
  };
  const svgPath = "M20 20 L80 50 L20 80 Z";
  const svgPathReversed = "M80 20 L20 50 L80 80 Z";


  const animateVariantsLRArrow = {
    visible: {
      transform: ['translateX(100%)'],
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
    hidden: {
      transform: ['translateX(20%)'],
      opacity: 0.5,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };


  const animateVariantsLR = {
    visible: {

      scaleX: 1,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },

    },
    hidden: {

      scaleX: 0,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };

  const animateVariantsRLArrow = {
    visible: {
      transform: ['translateX(-100%)'],
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
    hidden: {
      transform: ['translateX(-20%)'],
      opacity: .5,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };


  const animateVariantsRL = {
    visible: {
      x: "0px",

      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
    hidden: {
      x: "100%",

      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };

  if (props.title != 'Back') {
    return (
      <Container
        style={{
          width: '100%',
          height: 'auto',
          paddingTop: '10px',
          paddingBottom: '10px',
          backgroundColor: '#212529',

          borderRadius: '1px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          fontFamily: '"Urbanist", sans-serif',
          fontSize: '18px',
          cursor: 'pointer',

          zIndex: '1012',
        }
        }
        to={props.link}
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
        onClick={handleClick}
        key={props.title + "HEADER"}
        layout

        whileHover={{


          color: "white",
          transition: { duration: 0.3 },
        }}


        animate={{
          color: fontColor,
        }}
        transition={{ duration: 0.4 }}
        whileTap={{ scale: 0.8 }}
      >
        <Title>
          {props.title}
        </Title>

        <LoadedImageRev
          layout
          intial={"hidden"}
          src={forIgm}
          variants={animateVariantsLRArrow}
          animate={showArrow ? "visible" : "hidden"}
          alt="Example Image"
        />

        <BottomLine
          style={{
            transformOrigin: "left center",
            width: "100%",
            backgroundColor: "#CAD3D7",
            position: "fixed",
            bottom: "0%",
            height: "4px",
          }}
          variants={animateVariantsLR}
          animate={showArrow ? "visible" : "hidden"}
          initial={'hidden'}
        >

        </BottomLine>
      </Container >
    );
  } else {
    return (
      <AnimatePresence>
        <Container
          style={{
            width: '100%',
            height: 'auto',
            paddingTop: '10px',
            paddingBottom: '10px',
            backgroundColor: '#212529',

            borderRadius: '1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            fontFamily: '"Urbanist", sans-serif',
            fontSize: '18px',
            cursor: 'pointer',

            zIndex: '1012',
          }
          }
          onMouseEnter={handleHoverEnter}
          onClick={handleClickEnter}
          onMouseLeave={handleHoverLeave}

          key={props.title + "HEADER"}
          layout

          whileHover={{

            color: "white",
            transition: { duration: 0.3 },
          }}
        >



          <LoadedImageRev
            layout
            intial={"hidden"}
            src={backIgm}
            variants={animateVariantsRLArrow}
            animate={showArrow ? "visible" : "hidden"}
            alt="Example Image"
          />

          <Title>
            Home
          </Title>

          <BottomLine
            style={{
              transformOrigin: "left center",
              width: "100%",
              backgroundColor: "#CAD3D7",
              position: "fixed",
              bottom: "0%",
              height: "4px",
            }}
            variants={animateVariantsLR}
            animate={showArrow ? "visible" : "hidden"}
            initial={'hidden'}
          >

          </BottomLine>

        </Container>
      </AnimatePresence >
    );
  }
};

export default NavbarEXSub;

/*
style={{
            justifyContent: "right",
          }}
          whileHover={{
            justifyContent: "left",
          }}*/


/*

initial={{justifyContent: "center"}}
exit={{justifyContent: "center"}}
 
whileHover={{
  justifyContent: "left",
}}
whileTap={{
  justifyContent: "left",
}}

{
          showArrow && (
            <SvgWrapper viewBox="0 0 100 100">
              <ArrowPath
                d={svgPath}
                initial={{ pathLength: 0, opacity: 0 }}
                animate={{ pathLength: 1, opacity: 1 }}
                transition={{ duration: 0.3 }}
              />
            </SvgWrapper>
          )
        }

*/