import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ThreeJSApp from '../utility/Animations/ThreeJS/TJS_Utilities';
import Logo from '../Icons/LogoTitle';
import NM_DD from './../Navbar/Navbar_Mobile/NM_DD'

import AlImage from './../../imgs/WHAT-WE-SELL.jpg'
import CopperImage from './../../imgs/WHAT-WE-SELL-2.jpg'
import CloveImage from './../../imgs/WHAT-WE-SELL-3.jpg'

import SmelterImage from './../../imgs/SMELTER.jpg'
import Smelter2Image from './../../imgs/SMELTER-2.jpg'
import Smelter3Image from './../../imgs/SMELTER-5.jpg'
import PLantImage from './../../imgs/WechatIMG1924.jpeg'

import Carousel from '../ImageCarousel/Carousel';
import SlideMenuCard from '../SlideDisplayCard/SlideDisplayCard';

import Footer from './../Footer/Footer';

//#040e3d background-color: #CAD3D7;
const Container = styled(motion.div)`
  display: flex;

  position: relative;
  font-family: "Urbanist", sans-serif;
  flex-direction: column;
  align-items: center;
  

  margin: auto;
  padding-top: 5vh;
  padding-bottom: 0vh;

  min-height: 100%;
  height: auto;
  max-height: 200%;


  width: 100%;
  max-width: 100%;

  
  z-index: 1;
  
`;

const Title = styled.div`
  max-width: 80%;
  height: auto;
    margin-top: 2vh;
  font-size: 4rem;
  color: white;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  
  border-bottom: 1px solid black;
`;

const Card = styled.div`

font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: column;
align-items: center;
justify-content: auto;

margin-top: 2vh;


height: auto;

width: auto;

color: white;

backdrop-filter: blur(10px);

border-left: 0;

border-right: 0;
`;

const ImageCard = styled.div`
position: relative;
z-index: 1;
font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: auto;
align-items: center;
justify-content: space-evenly;

height: auto;
width: 100%;


background-color: RGB(255,255,255, 1);
backdrop-filter: blur(10px);

margin: 0;
padding-top: 10px;
padding-bottom: 10px;

overflow: hidden;
    
`;



const AnimatedDescription = styled(motion.p)`
  font-size: 2rem;
  max-width: 70%;
  
  font-family: 'Nunito Sans', sans-serif;
  color: #36A423;
`;


const TextCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  min-width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

const TextCardB = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

//font-family: 'Nunito Sans', sans-serif;
//font-family: poppins,Sans-serif;

const TextCardChild = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildE = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildI = styled.div`
  margin: 0;

  color: white;
  font-size: 1.6rem;

  height: 20vh;
  width: auto;
  
  margin: 0px;
  padding-left: 0px;
  
 
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: left;

  padding: 10px;
 
 
  
`;

const TextCardChildEI = styled.div`
  margin: 0;

  color: white;
  font-size: 1.6rem;

  height: 20vh;
  width: auto;
  
  margin: 0px;
  padding-left: 0px;
  
 
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: right;

  padding: 10px;
 
 
  
`;


const TextCardChildC = styled.div`
  margin: 0;
  color: white;
  max-width: 30vw;
  margin-left: 20px;
  text-align: left;
`;

const TextCardChildD = styled.div`
  margin: 0;
  color: RGB( 202, 211, 215, 0.9);
  max-width: 100%;
  margin-left: 20px;
  text-align: left;
`;


const TextCardChildA = styled.div`
  margin: 0;
  color: white;
  font-size: 5rem;
  margin: 20px;
  text-align: left;
    
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  max-width: 50%;
  min-width: 50%;
`;

const TextCardChildB = styled.div`
  margin: 0;
  color: white;
  font-size: 3rem;
  width: auto;
  
  text-align: left;
`;

const LoadedImage = styled(motion.img)`
    
    margin-top: 2vh;
    min-width: 33%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

const LoadedImageB = styled(motion.img)`
    
    
    max-width: 100%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

// Framer Motion variants
const containerVariants = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.3 } },
};

const buttonVariants = {
  hover: { scale: 1.05 },
};

const Operations = (props) => {


  useEffect(() => {
    // ...
  }, [props.isLoading]);

  const word = "Recycled ingot production at it's finest";

  const animateDescription = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const highlightVariants = {
    hidden: { opacity: 1, color: 'Black' },
    visible: { opacity: 1, color: '#36A423' },
  };


  const images = [
    PLantImage,
    SmelterImage,
    Smelter2Image,
    Smelter3Image,
    
    // Add more image URLs here
  ];

  const ncuGroupInfo = `
  With over two decades of experience, NCU Group has established itself as a
   leader in the field of non-ferrous metal recycling and ingot production. We are dedicated to 
   sustainability and environmental responsibility, making us your go-to partner
    for recycling a wide range of materials, from cables and car wires to electric
     motors and heavy metals, to bulk purchasing of available materials.
  `;


  const ncuGroupInfo2 = `
  Our team consists of seasoned engineers and a dedicated management staff,
   bringing together more than 30 years of technical and industry expertise.
    This wealth of knowledge ensures that we employ the most advanced techniques
     and technologies in the recycling industry.
  `;

  const ncuGroupInfo3 = `
  At NCU Group, we understand that each client has unique requirements. 
  That's why we offer tailored solutions to meet your specific needs. 
  Our state-of-the-art facilities can smelt 3,000 MT of aluminum and 2,000 MT of copper per month, 
  ensuring we can accommodate even the most demanding projects.
  `;

  const ncuGroupInfo4 = `
  Our commitment to quality extends to the products we produce. We take pride
   in delivering top-tier recycled materials to our customers. Our current 
   product offerings include: ADC 12 Aluminum Ingots, Copper Ingots, & Copper Wire Granule Scrap “Clove”
  `;


  //We primarily import stainless steel and non-ferrous metal scraps from the US, Europe, and Japan. Currently, per month we can produce 3000MT of aluminium, and 2000MT of copper.
  const ncuGroupTitle = `
    Crafting. Precision. Excellence.
    `;

  const words = ncuGroupInfo.split(/\s+/);

  // Group words into arrays of 5 words each
  const groupedWords = [];
  for (let i = 0; i < words.length; i += 6) {
    groupedWords.push(words.slice(i, i + 5).join(' '));
  }

  return (
    <Container variants={containerVariants} initial="initial" animate={!props.isLoading || true ? "animate" : "initial"}>

      <TextCard>
        <TextCardChildA >
          <TextCardChildB id={'Products-AL'}>
            NCU
          </TextCardChildB>

        </TextCardChildA >
        <TextCardChild >
          <TextCardChildD>{ncuGroupInfo}</TextCardChildD>
        </TextCardChild >
        <TextCardChild >
          <TextCardChildD>{ncuGroupInfo2}</TextCardChildD>
        </TextCardChild >
        <TextCardChild >
          <TextCardChildD>{ncuGroupInfo4}</TextCardChildD>
        </TextCardChild >
        <TextCardChild >
          <TextCardChildD>{ncuGroupInfo3}</TextCardChildD>
        </TextCardChild >
      </TextCard>

      <TextCard>
        <Carousel images={images}>
        </Carousel>
      </TextCard>


      <Footer></Footer>


    </Container>
  );

  //Copper Wirer Granule Scarp “Clove”

  /*
  
          */
};




export default Operations;

