import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const TRUE = '1'
const FALSE = '0'

const FPS_MODE_120 = 120
const FPS_MODE_60 = 60
const FPS_MODE_30 = 30
const FPS_MODE_0 = 0

const DropdownWrapper = styled.div`
  position: fixed;
  width: 100vw;
`;

const TriggerButton = styled.button`
  padding: 10px;
  background: teal;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  width: 200px;
`;

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 1;
  }
`;
//${props => (props.dropdownlistheight == 0) ? "visible" : "visible"} visibility: "visible";
const DropdownList = styled.ul`
animation: ${props => (props.$isvisible ? slideIn : slideOut)} ${props => props.$fpsmode}s ease-in-out forwards;
  background: #040e3d;
  width: 100vw;
  color: #ffffff;
  list-style: none;
  margin: 0;
  font-size: 40px;
  overflow: hidden;
  padding: 0;
  overflow: auto;
  transform-origin: bottom;
  position: absolute;
`;

const DropdownItem = styled.li`
  margin-bottom: 2px;

  &:hover {
    font-size: 19px;
    cursor: pointer;
  }
`;

const BasicDropdown_KeyFrames = (props) => {
  const items = ["Keyframes", "apple", "orange", "pear", "grape", "berry"];



  const dropdownListRef = useRef(null);
  const [dropdownListHeight, setDropdownListHeight] = useState(0);

  useEffect(() => {
    if (dropdownListRef.current) {
      setDropdownListHeight(dropdownListRef.current.clientHeight); // Set the height when the component mounts
      //console.log("dropDownHeight: ", dropdownListRef.current.clientHeight)
    }
  }, [dropdownListRef.current]);

  useEffect(() => {
    if (dropdownListRef.current) {
      setDropdownListHeight(dropdownListRef.current.clientHeight); // Set the height when the component mounts
      //console.log("dropDownHeight 1: ", dropdownListRef.current.clientHeight)
    }
  }, []);

  const [duration, setDuration] = useState(0.3);

    useEffect(() => {
        let fps = props.fpsMode
        if(fps > 0){
            if(fps > FPS_MODE_60){
                setDuration(0.25)
            } else if((fps > FPS_MODE_30 && fps <= FPS_MODE_60)){
                setDuration(0.6)
            } else if (fps <= FPS_MODE_30 ){
                setDuration(0.6)
            }
          } else {
            setDuration(0.6);
          }
    }, [props.fpsMode]);


  return (
    <DropdownWrapper>
      <DropdownList
        $isvisible={props.showDropdown}
        $dropdownlistheight={dropdownListHeight}
        ref={dropdownListRef}
        $fpsmode = {duration}
      >
        {true && (
          <div
            key={"SpacingBox"}
            style={{ height: props.navbarHeight }}
          ></div>
        )}

        {items.map((item, index) => (
          <DropdownItem key={index}>{item}</DropdownItem>
        ))}
      </DropdownList>
    </DropdownWrapper>
  );
};

export default BasicDropdown_KeyFrames;
