import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

import logo from '../../imgs/NCU-Logo-Transparent.png'

const icon = {
    hidden: {
        opacity: 0,
        pathLength: 0,
        fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
        opacity: 1,
        pathLength: 1,
        fill: "rgba(255, 255, 255, 1)"
    }
};

const Container = styled(motion.div)`
    width: 10vh;
    height: auto; /* Let the height adjust automatically based on content */
    max-height: 10vh
    min-width: 5vh;
    min-height: 5vh;
    
    display: flex;
    place-content: center;
    overflow: hidden;
    background: transparent;
    left: 0;
`;
const Item = styled(motion.svg)`
    width: 100vw;
    overflow: visible;
    stroke: #fff;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
`;
const LoadedImage = styled(motion.img)`
    width: auto;
    height: 20vh;
    min-height: 10vh; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

const Logo = () => {

    const [logoFromSavedFile, setlogoFromSavedFile] = useState(true);
    return (

        <Container>

            {!logoFromSavedFile && (
                <Item
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 1200"
                    className="item"
                >

                    <motion.path
                        d="M 100 750 L 100 50 L 100 50 L 450 400 L 450 50 L 500 50 L 500 750 L 150 400 L 150 300 L 450 600 L 450 500 L 150 200 L 150 750 L 100 750"
                        /*d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"*/
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{
                            default: { duration: 2, ease: "easeInOut" },
                            fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                        }}
                    />

                </Item>
            )}

            {logoFromSavedFile && (
                <LoadedImage src={logo} alt="" />
            )}

        </Container>

    );
}

export default Logo