import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ThreeJSApp from '../utility/Animations/ThreeJS/TJS_Utilities';
import Logo from '../Icons/LogoTitle';
import NM_DD from './../Navbar/Navbar_Mobile/NM_DD'

import AlImage from './../../imgs/WHAT-WE-SELL.jpg'
import CopperImage from './../../imgs/WHAT-WE-SELL-2.jpg'
import CloveImage from './../../imgs/WHAT-WE-SELL-3.jpg'

import SmelterImage from './../../imgs/SMELTER.jpg'
import Smelter2Image from './../../imgs/SMELTER-2.jpg'
import Smelter3Image from './../../imgs/SMELTER-5.jpg'

import Footer from './../Footer/Footer';
import Carousel from '../ImageCarousel/Carousel';
import SlideMenuCard from '../SlideDisplayCard/SlideDisplayCard';

import tomatoImage from './../../imgs/WHAT-WE-SELL.jpg'; // Import the tomato image

import AlTableImg from './../../imgs/Al_table.png';
//#040e3d background-color: #CAD3D7;
const Container = styled(motion.div)`
  display: flex;

  position: relative;
  font-family: "Urbanist", sans-serif;
  flex-direction: column;
  align-items: center;
  

  margin: auto;
  padding-top: 5vh;
  padding-bottom: 0vh;

  min-height: 100%;
  height: auto;
  max-height: 200%;


  width: 100%;
  max-width: 100%;

  
  z-index: 1;
  
`;

const Title = styled.div`
  max-width: 80%;
  height: auto;
    margin-top: 2vh;
  font-size: 4rem;
  color: white;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  
  border-bottom: 1px solid black;
`;

const Card = styled.div`

font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: column;
align-items: center;
justify-content: auto;

margin-top: 2vh;


height: auto;

width: auto;

color: white;

backdrop-filter: blur(10px);

border-left: 0;

border-right: 0;
`;

const ImageCard = styled.div`
position: relative;
z-index: 1;
font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: auto;
align-items: center;
justify-content: space-evenly;

height: auto;
width: 100%;


background-color: RGB(255,255,255, 1);
backdrop-filter: blur(10px);

margin: 0;
padding-top: 10px;
padding-bottom: 10px;

overflow: hidden;
    
`;



const AnimatedDescription = styled(motion.p)`
  font-size: 2rem;
  max-width: 70%;
  
  font-family: 'Nunito Sans', sans-serif;
  color: #36A423;
`;

const TextCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

const TextCardB = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

//font-family: 'Nunito Sans', sans-serif;
//font-family: poppins,Sans-serif;

const TextCardChild = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildE = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildC = styled.div`
  margin: 0;
  color: white;
  max-width: 30vw;
  margin-left: 20px;
  text-align: left;
`;

const TextCardChildD = styled.div`
  margin: 0;
  color: white;
  max-width: 50vw;
  margin-left: 20px;
  text-align: left;
`;


const TextCardChildA = styled.div`
  margin: 0;
  color: white;
  font-size: 5rem;
  margin: 20px;
  text-align: left;
    
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  
`;

const TextCardChildB = styled.div`
  margin: 0;
  color: white;
  font-size: 3rem;
  width: auto;
  
  text-align: left;
`;

const LoadedImage = styled(motion.img)`
    
    padding-top: 2vh;
    max-width: 70%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

const TextCard_ = styled.div`

backdrop-filter: blur(2px);
-webkit-backdrop-filter: blur(2px); 
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: column;
  align-items: center;
  
  padding-top: 2vh;
  border-radius: 4px;
  padding-bottom: 2vh;
  
  width: 100%;
  max-width: 100vw;
  min-width: 100vw;

  
  
  height: auto;
   /* You can adjust the maximum width as needed */
  

`;

const TextCardChild_ = styled.div`
  
color:  RGB( 33, 37, 41, 1);
font-size: 2rem;
  width: auto;
  max-width: 100%;
  min-width: 50%;
  margin-left: 2%;
  padding-left: 2%;
  
  text-align: left;
 
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

    
  
  flex-direction: row;
  justify-content: left;
  
`;

const TextCardChildAA = styled.div`
    
  color: white;
  

  width: 100%;
  max-width: 100%;
 
  margin: 0;
  padding-left: 2%;

  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: row;
  
`;

const TextCardChildBB = styled.div`

  margin: 0;
  padding-right: 1%;
  padding-bottom: 0.5%;
  margin-bottom: 1%;
  border-bottom: 3px solid white;
  color:  white;
  font-size: 3rem;
  width: auto;
  max-width: 33%;
  
  text-align: center;
  
`;

const TextCardChildCC = styled.div`
  
color: RGB( 202, 211, 215, 0.9);
  
  width: auto;
  max-width: 100%;
  
  text-align: left;
  
`;

// Framer Motion variants
const containerVariants = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.3 } },
};

const buttonVariants = {
  hover: { scale: 1.05 },
};

const Products_AL = (props) => {


  useEffect(() => {
    // ...
  }, [props.isLoading]);

  const word = "Recycled ingot production at it's finest";

  const animateDescription = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const highlightVariants = {
    hidden: { opacity: 1, color: 'Black' },
    visible: { opacity: 1, color: '#36A423' },
  };


  const images = [
    SmelterImage,
    Smelter2Image,
    Smelter3Image,
    // Add more image URLs here
  ];

  const ncuGroupInfo = `
  Aluminum ADC 12 ingots have diverse applications across various industries. 
  Their versatility makes them invaluable for automotive components, 
  such as engine parts, transmission housings, and wheels, where 
  their lightweight yet strong properties are highly advantageous. 
  In the electronics sector, ADC 12 ingots are used to craft heat 
  sinks and electronic enclosures due to their excellent thermal 
  conductivity and corrosion resistance. Moreover, these ingots 
  are employed in the construction industry to create durable 
  fixtures and fittings, showcasing their adaptability in numerous 
  manufacturing applications.
  `;


  const ncuGroupInfo2 = `
  Copper ingots are essential commodities in various industries 
  due to their versatility and exceptional properties. These ingots 
  are primarily used in the production of electrical wiring, where 
  copper's excellent electrical conductivity ensures efficient power 
  transmission. In the construction sector, copper ingots find their 
  way into plumbing systems, roofing materials, and architectural 
  details, thanks to their corrosion resistance and longevity. 
  The automotive industry benefits from copper ingots in radiator manufacturing,
   as they offer efficient heat transfer properties. Additionally, copper ingots
    play a pivotal role in the creation of intricate artworks and sculptures, 
    showcasing their malleability and aesthetic appeal. With applications 
    spanning from electronics to art, copper ingots remain a vital material 
    in today's industrial landscape.
  `;

  const ncuGroupInfo3 = `
Copper wire granule scrap, often referred to as "Clove," is a valuable 
material with several practical use cases in various industries. 
These granules are typically derived from recycled copper wires and cables,
 and their versatility makes them highly sought after. In the electrical 
 and electronics industry, Clove scrap is melted down to create new copper 
 products, including conductive wires and components. Its excellent electrical 
 conductivity and corrosion resistance make it a prime choice for maintaining 
 high-quality electrical connections. Additionally, in the metallurgy and construction 
 sectors, Clove scrap can be alloyed with other metals to enhance their properties, 
 such as strength and corrosion resistance. Its sustainable nature also aligns with 
 environmentally conscious practices, contributing to the circular economy by reducing 
 the need for mining and processing of new copper resources. Overall, Clove scrap plays 
 a pivotal role in the efficient utilization of copper resources across multiple industries.
  `;


  //We primarily import stainless steel and non-ferrous metal scraps from the US, Europe, and Japan. Currently, per month we can produce 3000MT of aluminium, and 2000MT of copper.
  const ncuGroupTitle = `
    Crafting. Precision. Excellence.
    `;

  const words = ncuGroupInfo.split(/\s+/);

  // Group words into arrays of 5 words each
  const groupedWords = [];
  for (let i = 0; i < words.length; i += 6) {
    groupedWords.push(words.slice(i, i + 5).join(' '));
  }

  return (
    <Container variants={containerVariants} initial="initial" animate={!props.isLoading || true ? "animate" : "initial"}>
      <TextCard>
        <TextCardChildA >
          <TextCardChildB>
            Aluminium Ingots
          </TextCardChildB>
        </TextCardChildA >
      </TextCard>

      <TextCard>

        <TextCardChild_ >
          <TextCardChildCC>{ncuGroupInfo}</TextCardChildCC>
        </TextCardChild_ >
        <LoadedImage src={AlTableImg}>
        </LoadedImage>
        <LoadedImage src={tomatoImage}>
        </LoadedImage>
        
      </TextCard>




      <Footer></Footer>


    </Container>
  );

  //Copper Wirer Granule Scarp “Clove”
};




export default Products_AL;

