import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ThreeJSApp from '../utility/Animations/ThreeJS/TJS_Utilities';
import Logo from '../Icons/LogoTitle';
import NM_DD from './../Navbar/Navbar_Mobile/NM_DD'

import RECImg from './../../imgs/recycle-solid.svg'
import CabImg from './../../imgs/cables.jpg'
import EMImg from './../../imgs/ElectricMot.jpg'
import HMImg from './../../imgs/HeavyMetal.jpg'
import IncImg from './../../imgs/Incin.jpg'
import MBImg from './../../imgs/MeatBall.jpg'
import RadImg from './../../imgs/Rad.jpg'
import StartImg from './../../imgs/Starters.jpg'
import SSImg from './../../imgs/Steel.jpg'
import TransImg from './../../imgs/Trans.jpg'
import TWImg from './../../imgs/Twitch.jpg'
import ZorbaImg from './../../imgs/Zorba.jpg'


import AlImage from './../../imgs/WHAT-WE-SELL.jpg'
import CopperImage from './../../imgs/WHAT-WE-SELL-2.jpg'
import CloveImage from './../../imgs/WHAT-WE-SELL-3.jpg'

import SmelterImage from './../../imgs/SMELTER.jpg'
import Smelter2Image from './../../imgs/SMELTER-2.jpg'
import Smelter3Image from './../../imgs/SMELTER-5.jpg'

import Footer from './../Footer/Footer';
import Carousel from '../ImageCarousel/Carousel';
import SlideMenuCard from '../SlideDisplayCard/SlideDisplayCard';

import TextCardComponent from './TextCard';

//#040e3d background-color: #CAD3D7;
const Container = styled(motion.div)`
  display: flex;

  position: relative;
  font-family: "Urbanist", sans-serif;
  flex-direction: column;
  align-items: center;
  

  margin: auto;
  padding-top: 5vh;
  padding-bottom: 0vh;

  min-height: 100%;
  height: auto;
  max-height: 200%;


  width: 100%;
  max-width: 100%;

  
  z-index: 1;
  
`;

const Title = styled.div`
  max-width: 80%;
  height: auto;
    margin-top: 2vh;
  font-size: 4rem;
  color: white;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  
  border-bottom: 1px solid black;
`;

const Card = styled.div`

font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: column;
align-items: center;
justify-content: auto;

margin-top: 2vh;


height: auto;

width: auto;

color: white;

backdrop-filter: blur(10px);

border-left: 0;

border-right: 0;
`;

const ImageCard = styled.div`
position: relative;
z-index: 1;
font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: auto;
align-items: center;
justify-content: space-evenly;

height: auto;
width: 100%;


background-color: RGB(255,255,255, 1);
backdrop-filter: blur(10px);

margin: 0;
padding-top: 10px;
padding-bottom: 10px;

overflow: hidden;
    
`;



const AnimatedDescription = styled(motion.p)`
  font-size: 2rem;
  max-width: 70%;
  
  font-family: 'Nunito Sans', sans-serif;
  color: #36A423;
`;

const TextCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  min-width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

const TextCardB = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

//font-family: 'Nunito Sans', sans-serif;
//font-family: poppins,Sans-serif;

const TextCardChild = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildE = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildI = styled.div`
  margin: 0;

  color: white;
  font-size: 1.6rem;

  height: 20vh;
  width: auto;
  
  margin: 0px;
  padding-left: 0px;
  
 
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: left;

  padding: 10px;
 
 
  
`;

const TextCardChildEI = styled.div`
  margin: 0;

  color: white;
  font-size: 1.6rem;

  height: 20vh;
  width: auto;
  
  margin: 0px;
  padding-left: 0px;
  
 
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: right;

  padding: 10px;
 
 
  
`;


const TextCardChildC = styled.div`
  margin: 0;
  color: white;
  max-width: 30vw;
  margin-left: 20px;
  text-align: left;
`;

const TextCardChildD = styled.div`
  margin: 0;
  color: RGB( 202, 211, 215, 0.9);
  max-width: 100%;
  margin-left: 20px;
  text-align: left;
`;


const TextCardChildA = styled.div`
  margin: 0;
  color: white;
  font-size: 5rem;
  margin: 20px;
  text-align: left;
    
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  max-width: 50%;
  min-width: 50%;
`;

const TextCardChildB = styled.div`
  margin: 0;
  color: white;
  font-size: 3rem;
  width: auto;
  
  text-align: left;
`;

const LoadedImage = styled(motion.img)`
    
    margin-top: 2vh;
    min-width: 33%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

const LoadedImageB = styled(motion.img)`
    
    
    max-width: 100%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

// Framer Motion variants
const containerVariants = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.3 } },
};

const buttonVariants = {
  hover: { scale: 1.05 },
};

const Purchasing = (props) => {


  useEffect(() => {
    // ...
  }, [props.isLoading]);

  const word = "Recycled ingot production at it's finest";

  const animateDescription = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const highlightVariants = {
    hidden: { opacity: 1, color: 'Black' },
    visible: { opacity: 1, color: '#36A423' },
  };

  /*
 <TextCard>
        <TextCardComponent titles={['Cables', '& Car', 'Wiring']} img={CabImg}></TextCardComponent>
        <TextCardComponent titles={['Electric', 'Motors']} img={EMImg}></TextCardComponent>
        <TextCardComponent titles={['Heavy ', 'Metal']} img={HMImg}></TextCardComponent>
        <TextCardComponent titles={['Incineration']} img={IncImg}></TextCardComponent>
        <TextCardComponent titles={['Meat', 'Ball']} img={MBImg}></TextCardComponent>
        <TextCardComponent titles={['Radiators']} img={RadImg}></TextCardComponent>
        <TextCardComponent titles={['Starters']} img={StartImg}></TextCardComponent>
        <TextCardComponent titles={['Stainless', 'Steel']} img={SSImg}></TextCardComponent>
        <TextCardComponent titles={['Transformers']} img={TransImg}></TextCardComponent>
        <TextCardComponent titles={['Twitch']} img={TWImg}></TextCardComponent>
        <TextCardComponent titles={['Zorba']} img={ZorbaImg}></TextCardComponent>
      </TextCard>
      */


  const images = [
    CabImg,
    EMImg,
    HMImg,
    IncImg,
    MBImg,
    RadImg,
    StartImg,
    SSImg,
    TransImg,
    TWImg,
    ZorbaImg
    // Add more image URLs here
  ];



  const ncuGroupInfo = `
  NCU Group is committed to environmental sustainability through 
  its comprehensive recycling efforts. We are regularly in search 
  of new suppliers that can provide us with the necessary Non-Ferrous 
  scrap. We rely on our suppliers to deliver superior raw materials 
  that we convert into finished goods and products. Some of the metal 
  scrap types we typically purchase include turnings, cast, zorba, ICW,
   zurik, extrusion, incinerations, twitch, and more.
  `;


  const ncuGroupInfo2 = `
  NCU Group is committed to environmental sustainability through its comprehensive 
  recycling efforts. We are regularly in search of new suppliers that can provide 
  us with the necessary Non-Ferrous scrap. We rely on our suppliers to deliver superior 
  raw materials that we convert into finished goods and products. Some of the metal scrap 
  types we typically purchase include turnings, cast, zorba, ICW, zurik, extrusion, incinerations, twitch, and more.
  `;

  const ncuGroupInfo3 = `
  Copper ingots are essential commodities in various industries 
  due to their versatility and exceptional properties. These ingots 
  are primarily used in the production of electrical wiring, where 
  copper's excellent electrical conductivity ensures efficient power 

  `;


  //We primarily import stainless steel and non-ferrous metal scraps from the US, Europe, and Japan. Currently, per month we can produce 3000MT of aluminium, and 2000MT of copper.
  const ncuGroupTitle = `
    Crafting. Precision. Excellence.
    `;

  const words = ncuGroupInfo.split(/\s+/);

  // Group words into arrays of 5 words each
  const groupedWords = [];
  for (let i = 0; i < words.length; i += 6) {
    groupedWords.push(words.slice(i, i + 5).join(' '));
  }

  return (
    <Container variants={containerVariants} initial="initial" animate={!props.isLoading ? "animate" : "initial"}>



      
      <TextCard>
        <TextCardChildA >
          <TextCardChildB>
          Suppliers
          </TextCardChildB>
          <TextCardChildB>
            <LoadedImage src={RECImg}>
            </LoadedImage>
          </TextCardChildB>
        </TextCardChildA >
        <TextCardChild >
          <TextCardChildD>{ncuGroupInfo2}</TextCardChildD>
        </TextCardChild >
      </TextCard>

     


      <TextCard>
        <Carousel images={images}>
        </Carousel>
      </TextCard>

      <Footer></Footer>



    </Container>
  );


};




export default Purchasing;


