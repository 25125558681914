import React, { useEffect, useRef, useState } from 'react';

import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TTFLoader } from 'three/examples/jsm/loaders/TTFLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ParametricGeometry } from 'three/addons/geometries/ParametricGeometry.js';

import TWEEN from 'three/addons/libs/tween.module.js';
import { TrackballControls } from 'three/addons/controls/TrackballControls.js';
import { CSS3DRenderer, CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { FlakesTexture } from 'three/examples/jsm/textures/FlakesTexture';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader'


//import { FlakesTexture } from '.three/examples/jsm/controls/FlakesTexture.js';
//import { RGBELoader } from '.three/examples/jsm/controls/RGBELoader.js';

export class ThreeJSApp {

    //Constructor: Initializes the properties of the ThreeJSApp class, 
    //including the canvas reference, scene, camera, renderer, shape, and text.
    constructor() {
        this.canvasRef = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.shape = null;
        this.text = null;
        this.gltfScene = null;
    }



    createCanvasCSS3D(antialias = true, cameraZposition = 8, fov = 75) {
        let ratio = window.innerWidth / window.innerHeight
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(fov, ratio, 0.1, 1000);
        this.camera.position.z = cameraZposition - (ratio * ratio);

        this.renderer = new CSS3DRenderer({ antialias });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.canvasRef = this.renderer.domElement;
        document.body.appendChild(this.canvasRef);


        //this.renderer.setPixelRatio(window.devicePixelRatio);

        //this.renderer.setClearColor('red', 0);


        return this.canvasRef;
    }

    addHtmlElement(shapeRef, htmlElement, liveControl = true) {

        const boundingBox = new THREE.Box3().setFromObject(shapeRef);
        const frontFaceWidth = boundingBox.max.x - boundingBox.min.x;
        const frontFaceHeight = boundingBox.max.y - boundingBox.min.y;

        //console.log("shapeRef", shapeRef)
        const element = document.createElement('div');
        element.className = 'element';
        //element.style.width = shapeRef.geometry.parameters.width + 'px'; // Set width based on shape's width
        //element.style.height = shapeRef.geometry.parameters.height + 'px';

        element.style.width = frontFaceWidth + 'px'; // Set width based on shape's width
        element.style.height = frontFaceHeight + 'px';

        element.style.backgroundColor = 'red';
        //element.style.fontSize = "20px";
        //element.style = htmlElement.props.style;

        element.style.display = 'flex';
        element.style.justifyContent = 'center';
        element.style.alignItems = 'center';
        element.textContent = "TEST"
        element.style.fontSize = (shapeRef.geometry.parameters.height / shapeRef.geometry.parameters.width) - 0.1 + 'px';

        //const textNode = document.createTextNode('Hello, World!');

        // Append the text node to the element
        // element.appendChild(textNode);

        //console.log("textNode", element, textNode)
        const objectCSS = new CSS3DObject(element);
        objectCSS.position.x = 0;
        objectCSS.position.y = 0;
        objectCSS.position.z = 0;

        this.scene.add(objectCSS);

        const animate = () => {
            requestAnimationFrame(animate);
            this.renderer.render(this.scene, this.camera);
        };

        animate();

        if (liveControl) {
            const controls = new OrbitControls(this.camera, this.canvasRef);
            controls.update();
        }

        return objectCSS;

    }

    moveCSSto(CSSref, x, y, z) {
        this.targetPosition = this.createVector(x, y, z); // Update targetPosition
        this.CSSref = CSSref; // Save the provided shapeRef in the instance variable
        this.animate = this.animateCSS.bind(this); // Bind the animate method
        this.animateCSS(); // Start the animation
    }

    //createCanvas: Creates the three.js scene and sets up the camera and renderer. 
    //It returns the canvas element that you can add to your React component's DOM.
    createCanvas(antialias = true, cameraZposition = 8, fov = 75) {
        let ratio = window.innerWidth / window.innerHeight
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(fov, ratio, 0.1, 1000);
        this.camera.position.z = 10//500//cameraZposition - (ratio * ratio);
        this.renderer = new THREE.WebGLRenderer({ antialias });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor('red', 0);
        this.canvasRef = this.renderer.domElement;
        document.body.appendChild(this.canvasRef);
        return this.canvasRef;
    }

    //moveSceneTo: Moves the entire scene to the specified position.
    moveSceneTo(position) {
        this.scene.position.set(position.x, position.y, position.z);
    }

    //rotateScene: Rotates the entire scene based on the provided Euler rotation angles.
    rotateScene(rotation) {
        this.scene.rotation.set(rotation.x, rotation.y, rotation.z);
    }

    rotateGltf(rotation) {
        this.gltfScene.rotation.set(rotation.x, rotation.y, rotation.z);
        this.animate()
    }


    //createVector: A utility function to create a new THREE.Vector3 
    //instance based on the provided x, y, and z coordinates.
    createVector(x, y, z) {
        return new THREE.Vector3(x, y, z);
    }

    //createEuler: A utility function to create a new THREE.Euler 
    //instance based on the provided x, y, and z Euler rotation angles.
    createEuler(x, y, z) {
        return new THREE.Euler(x, y, z);
    }

    //moveShapeTo: Animates the provided shape to the specified position using linear interpolation (lerp).
    moveShapeTo(shapeRef, x, y, z) {
        this.targetPosition = this.createVector(x, y, z); // Update targetPosition
        this.shapeRef = shapeRef; // Save the provided shapeRef in the instance variable
        this.animate = this.animate.bind(this); // Bind the animate method
        this.animate(); // Start the animation
    }

    //animate: A recursive animation function that continuously updates the scene rendering to provide smooth animations.
    animate() {
        requestAnimationFrame(this.animate.bind(this)); // <-- Use bind to bind the context
        if (this.shapeRef) {
            this.shapeRef.position.lerp(this.targetPosition, 0.07);

        }
    }

    animateCSS() {
        requestAnimationFrame(this.animateCSS.bind(this));
        if (this.CSSref && this.targetPosition) {
            this.CSSref.position.lerp(this.targetPosition, 0.07);
            this.renderer.render(this.scene, this.camera);
        }
    }

    render() {

        this.renderer.render(this.scene, this.camera);

    }




    /*
    addHtmlElement(shapeRef, content, data) {
        
        // Create a new CSS3DObject with the provided HTML content
        const element = document.createElement('div');
        element.className = 'element';
        element.innerHTML = content;
    
        const objectCSS = new CSS3DObject(element);
    
        // Determine the position and rotation based on the shape type (table, sphere, helix, grid)
        // For simplicity, we'll use a random position and rotation for all shapes
        objectCSS.position.x = Math.random() * 4000 - 2000;
        objectCSS.position.y = Math.random() * 4000 - 2000;
        objectCSS.position.z = Math.random() * 4000 - 2000;
    
        objectCSS.rotation.x = Math.random() * 2 * Math.PI;
        objectCSS.rotation.y = Math.random() * 2 * Math.PI;
        objectCSS.rotation.z = Math.random() * 2 * Math.PI;
    
        // Add the CSS3DObject to the scene
        this.scene.add(objectCSS);
    
        // Store the object reference in the objects array
        //this.objects.push(objectCSS);
    
        // Log the shape reference and content
        console.log('Adding HTML element to shape:', shapeRef);
        console.log('HTML content:', content);
      }

    /*
    addHtmlElement(shapeRef, htmlElement) {
        if (!shapeRef || !htmlElement) {
            console.error('Invalid shapeRef or htmlElement provided.');
            return;
        }
    
        // Create a CSS3D renderer
        const labelRenderer = new CSS3DRenderer();
        labelRenderer.setSize(window.innerWidth, window.innerHeight);
    
        // Append the renderer's DOM element to the document body
        document.body.appendChild(labelRenderer.domElement);
    
        // Create a CSS3D object from the HTML element
        const cssObject = new CSS3DObject();
        
        labelRenderer.setSize(window.innerWidth, window.innerHeight);
        labelRenderer.domElement.style = htmlElement.props.style
        
        /*
        const labelRenderer = new CSS2DRenderer();
        labelRenderer.setSize(window.innerWidth, window.innerHeight);
        labelRenderer.domElement.style = htmlElement.props.style
        document.body.appendChild(labelRenderer.domElement)
        labelRenderer.render(this.scene, this.camera)
        

        // Set the position of the CSS3D object to match the 3D shape
        cssObject.position.copy(shapeRef.position);
    
        // Set the rotation of the CSS3D object to match the 3D shape (optional)
        cssObject.rotation.copy(shapeRef.rotation);
    
        // Add the CSS3D object to the scene
        this.scene.add(cssObject);
    
        // Render the scene with the CSS3D renderer
        labelRenderer.render(this.scene, this.camera);
    }
    */

    //createText: Creates a three.js mesh for displaying 3D text using the provided TrueType Font (TTF) file.
    //The OrbitControls allow for interaction with the text.
    createText(canvasRef) {
        const fontLoader = new FontLoader();
        const ttfLoader = new TTFLoader();
        const fontPath = '../../data/fonts/jet_brains_mono_regular.ttf';

        ttfLoader.load(fontPath, (json) => {
            const font = fontLoader.parse(json);

            const textGeometry = new TextGeometry('Hello Three.js!', {
                font: font,
                size: 0.1,
                height: 1,
                depth: 0
            });

            const textMaterial = new THREE.MeshNormalMaterial();
            this.text = new THREE.Mesh(textGeometry, textMaterial);
            this.text.position.set(0, 0, 0);
            this.scene.add(this.text);

            this.renderer.render(this.scene, this.camera);
        });

        const animate = () => {
            requestAnimationFrame(animate);
            this.renderer.render(this.scene, this.camera);
        };

        animate();

        const controls = new OrbitControls(this.camera, canvasRef);
        controls.update();
    }

    // Function to create an environment map using colors
    createEnvironmentMapPC(colors) {
        const cubeTexture = new THREE.CubeTexture([]);
        cubeTexture.format = 1025; // THREE.RGBFormat

        const loader = new THREE.ImageLoader();
        const promises = [];

        for (let i = 0; i < colors.length; i++) {
            const promise = new Promise((resolve) => {
                const color = colors[i];
                const canvas = document.createElement('canvas');
                canvas.width = 1;
                canvas.height = 1;

                const context = canvas.getContext('2d');
                context.fillStyle = color;
                context.fillRect(0, 0, 1, 1);

                canvas.toBlob((blob) => {
                    const imageUrl = URL.createObjectURL(blob);
                    resolve(imageUrl);
                }, 'image/jpeg');
            });

            promises.push(promise);
        }

        return Promise.all(promises).then((imageUrls) => {
            cubeTexture.images = imageUrls.map((imageUrl) => {
                const image = new Image();
                image.src = imageUrl;
                return image;
            });

            cubeTexture.needsUpdate = true;
            this.scene.background = cubeTexture;

            return cubeTexture; // Return the created environment map
        });
    }

    // Function to create an environment map using images
    createEnvironmentMapImage(imageUrls) {
        const loader = new THREE.CubeTextureLoader();
        const cubeTexture = loader.load(imageUrls);
        //const cubeTexture = new THREE.CubeTexture([]);
        cubeTexture.format = 1025; // THREE.RGBFormat

        this.scene.background = cubeTexture;
    }

    /*
    addLighting() {
        // Create an ambient light to provide overall illumination
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        this.scene.add(ambientLight);
    
        // Create a directional light to simulate sunlight
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(1, 1, 1); // Set the direction of the light
        this.scene.add(directionalLight);
    
        // Create a point light to simulate a light bulb
        const pointLight = new THREE.PointLight(0xffffff, 1, 100);
        pointLight.position.set(0, 3, 0); // Set the position of the light
        this.scene.add(pointLight);
    
        // Create a spotlight for highlighting specific objects
        const spotlight = new THREE.SpotLight(0xffffff, 1);
        spotlight.position.set(0, 5, 0); // Set the position of the spotlight
        spotlight.target.position.set(0, 0, 0); // Set the target for the spotlight
        spotlight.angle = Math.PI / 6; // Set the spotlight cone angle
        spotlight.penumbra = 0.2; // Set the penumbra (softness) of the spotlight
        spotlight.castShadow = true; // Enable shadow casting for the spotlight
        this.scene.add(spotlight);
    }
    */
    addLighting() {

        // Create a directional light to simulate sunlight from behind the camera
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        const cameraPosition = this.camera.position.clone();
        const cameraDirection = this.camera.getWorldDirection(new THREE.Vector3());
        directionalLight.position.copy(cameraPosition).sub(cameraDirection);
        this.scene.add(directionalLight);

        // Create an ambient light to provide some overall illumination
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.2); // Adjust intensity
        this.scene.add(ambientLight);

        // Create a point light to simulate a light source
        const pointLight = new THREE.PointLight(0xffffff, 1, 100);
        pointLight.position.set(0, 5, 5); // Adjust the position of the light if needed
        this.scene.add(pointLight);

        // Create a spotlight for highlighting specific objects
        const spotlight = new THREE.SpotLight(0xffffff, 1);
        spotlight.position.set(0, 0, 10); // Adjust the position of the spotlight if needed
        spotlight.target.position.set(0, 0, 0); // Set the target for the spotlight
        spotlight.angle = Math.PI / 6; // Set the spotlight cone angle
        spotlight.penumbra = 0.2; // Set the penumbra (softness) of the spotlight
        spotlight.castShadow = true; // Enable shadow casting for the spotlight
        this.scene.add(spotlight);
    }


    /*
    createShape: Creates various shapes like Box, Sphere, Cylinder, TorusKnot, and MobiusStrip using the provided parameters. 
    The function sets up the materials, colors, and wireframe if required. It also returns the created shape.
    
    canvasRef: The reference to the canvas element where the scene will be rendered.
    shapeType: The type of shape to create ('box', 'sphere', 'cylinder', 'torusKnot', or 'mobiusStrip').
    shapeGeometry: An object containing additional geometry parameters for specific shapes (e.g., radius, tube, tubularSegments, radialSegments, etc.).
    shapeColor: The color of the shape (can be a single color or an array of colors for specific cases).
    startingPosition: The initial position of the shape in the scene.
    startingRotation: The initial Euler rotation angles for the shape.
    showWireframe: A boolean flag indicating whether to display the wireframe of the shape.
    liveControl: A boolean flag indicating whether to enable the OrbitControls for interaction with the shape. 

    */
    createShape(canvasRef, shapeType, shapeGeometry, shapeColor, startingPosition, startingRotation, showWireframe = false, liveControl = false) {
        let geometry;
        let material;

        if (showWireframe) {
            material = new THREE.MeshBasicMaterial({ color: shapeColor, transparent: true, opacity: 0 });
        } else {
            material = new THREE.MeshBasicMaterial({ color: shapeColor, transparent: false, opacity: 1 });
        }


        if (shapeType === 'box') {
            geometry = new THREE.BoxGeometry();
        } else if (shapeType === 'sphere') {
            geometry = new THREE.SphereGeometry();
        } else if (shapeType === 'cylinder') {
            geometry = new THREE.CylinderGeometry();
        } else if (shapeType === 'torusKnot') {
            geometry = new THREE.TorusKnotGeometry(
                shapeGeometry.radius,
                shapeGeometry.tube,
                shapeGeometry.tubularSegments,
                shapeGeometry.radialSegments,
                shapeGeometry.p,
                shapeGeometry.q
            );
        } else if (shapeType === 'mobiusStrip') {
            const radialSegments = shapeGeometry.radialSegments || 150;
            const tubularSegments = shapeGeometry.tubularSegments || 150;
            const width = shapeGeometry.width || 1;
            const height = shapeGeometry.height || 1;
            const p = shapeGeometry.p || 20;
            const q = shapeGeometry.q || 18;

            geometry = new ParametricGeometry((u, v, target) => {
                const phi = 2 * Math.PI * u;
                const theta = Math.PI * v - Math.PI / 2;

                const x = (1 + 0.5 * v * Math.cos(p * phi / 2)) * Math.cos(phi);
                const y = (1 + 0.5 * v * Math.cos(p * phi / 2)) * Math.sin(phi);
                const z = 0.5 * v * Math.sin(p * phi / 2);

                target.set(x, y, z);
            }, radialSegments, tubularSegments);

            // Assign vertex colors to the geometry 
            const vertexColors = [];
            const color1Side1 = new THREE.Color(shapeColor[0]);   // Color for side 1 of face 1
            const color2Side1 = new THREE.Color(shapeColor[1]); // Color for side 2 of face 1
            const color1Side2 = new THREE.Color(shapeColor[2]); // Color for side 1 of face 2
            const color2Side2 = new THREE.Color(shapeColor[3]);  // Color for side 2 of face 2

            const positions = geometry.attributes.position.array;
            const colors = new Float32Array(positions.length);

            const faceCount = positions.length / 9;

            for (let i = 0; i < positions.length; i += 9) {
                const faceIndex = Math.floor(i / 9);
                const materialIndex = i < faceCount * 4 ? 0 : 1; // Alternates between 0 and 1 for each face

                //u=0
                const colorSide1 = materialIndex === 0 ? color1Side1 : color2Side1;
                //const colorSide2 = color1Side1//materialIndex === 0 ? color2Side1 : color2Side1;

                //u=1
                const colorSide2 = materialIndex === 0 ? color1Side2 : color2Side2;

                const u = 1//= Math.floor(faceIndex / 2) % 2; // 0 or 1 for each side of the strip

                for (let j = 0; j < 3; j++) {
                    const index = i + j * 3;

                    // Assign colors based on side and position
                    if (u === 0) {
                        colors[index] = colorSide1.r;
                        colors[index + 1] = colorSide1.g;
                        colors[index + 2] = colorSide1.b;
                    } else {
                        colors[index] = colorSide2.r;
                        colors[index + 1] = colorSide2.g;
                        colors[index + 2] = colorSide2.b;
                    }
                }
            }

            console.log(colors)
            geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

            const material_ = new THREE.MeshBasicMaterial({ vertexColors: true, side: THREE.DoubleSide, transparent: false, opacity: 1 });
            this.shape = new THREE.Mesh(geometry, material_);

        } else if (shapeType === 'Metalbox') {

            // Call createEnvironmentMapPC function to create an environment map using colors
            const environmentMapColors = ['white', 'white', 'white', 'white', 'white', 'white'];
            this.createEnvironmentMapPC(environmentMapColors).then((cubeTexture) => {
                // Create a metal cube with the custom environment map
                const metalCubeGeometry = new THREE.BoxGeometry();
                const metalCubeBaseColor = new THREE.Color('#CAD3D7');



                // Create a mesh material for the metal cube
                const metalCubeMaterial = new THREE.MeshStandardMaterial({
                    color: metalCubeBaseColor,
                    metalness: 1,
                    roughness: 0.5,
                    envMap: cubeTexture, // Use the custom environment map here
                });

                // Create the metal cube mesh
                this.shape = new THREE.Mesh(metalCubeGeometry, metalCubeMaterial);
                this.shape.position.copy(startingPosition);
                this.shape.rotation.set(startingRotation.x, startingRotation.y, startingRotation.z);
                this.scene.add(this.shape);
            });

            this.addLighting()


        } else if (shapeType === 'MetalSphere') {

            let texture = new THREE.CanvasTexture(new FlakesTexture());
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            //repeat the wrapping 10 (x) and 6 (y) times
            texture.repeat.x = 10;
            texture.repeat.y = 6;

            const ballMaterial = {
                clearcoat: 1.0,
                cleacoatRoughness: 0.1,
                metalness: 0.9,
                roughness: 0.5,
                color: 0x8418ca,
                normalMap: texture,
                //normalScale: new THREE.Vector2(0.15, 0.15),
                //envMap: envmap.texture
            };

            let ballGeo = new THREE.SphereGeometry(100, 64, 64);
            let ballMat = new THREE.MeshPhysicalMaterial(ballMaterial);
            this.shape = new THREE.Mesh(ballGeo, ballMat);

            this.renderer.outputEncoding = THREE.sRGBEncoding;
            this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
            this.renderer.toneMappingExposure = 2.25;
            this.shape.position.copy(startingPosition);
            this.shape.rotation.set(startingRotation.x, startingRotation.y, startingRotation.z);
            this.scene.add(this.shape);



            let envmaploader = new THREE.PMREMGenerator(this.renderer);

            /*
            new RGBELoader().load('cayley_interior_4k.hdr', function (hdrmap) {

                let envmap = envmaploader, fromCubemap(hdrmap);
                const ballMaterial = {
                    clearcoat: 1.0,
                    cleacoatRoughness: 0.1,
                    metalness: 0.9,
                    roughness: 0.5,
                    color: 0x8418ca,
                    normalMap: texture,
                    normalScale: new THREE.Vector2(0.15, 0.15),
                    envMap: envmap.texture
                };
            });
            */
            this.addLighting()



            //add material setting





        } else if (shapeType === 'Goldbar') {
            const loader = new GLTFLoader();

            loader.load('Assets/gold_bar_low_poly/scene.gltf',
                // onLoad callback
                (gltf) => {
                    const model = gltf.scene;
                    console.log(model);

                    this.gltfScene = gltf.scene;
                    this.scene.add(gltf.scene);

                    model.rotation.set(Math.PI / 2, -Math.PI / 2, Math.PI / 4);
                    // Asset loaded successfully
                    console.log("Asset loaded successfully!");
                },
                // onProgress callback
                (xhr) => {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                // onError callback
                (error) => {
                    console.error("Error loading asset:", error);
                }
            );

            this.addLighting();
        } else if (shapeType === 'AluminumBar__2') {
            const loader = new OBJLoader();

            loader.load('Assets/Aluminum_Bars/Aluminium_Ingot_Model.obj',
                // onLoad callback
                (object) => {
                    const model = object;
                    console.log(model);

                    this.objModel = object;
                    this.scene.add(object);

                    model.rotation.set(Math.PI / 2, -Math.PI / 2, Math.PI / 4);
                    // Asset loaded successfully
                    console.log("Asset loaded successfully!");
                },
                // onProgress callback
                (xhr) => {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                // onError callback
                (error) => {
                    console.error("Error loading asset:", error);
                }
            );

            this.addLighting();
        } else if (shapeType === 'Al2Bar') {
            const mtlLoader = new MTLLoader();
            mtlLoader.setPath('Assets/Aluminum_Bars/');

            mtlLoader.load('model.mtl', (materials) => {
                materials.preload();

                // Adjust texture paths to use jpg
                materials.map = materials.map.replace('.tif', '.jpg');
                materials.bumpMap = materials.bumpMap.replace('.tif', '.jpg');

                const objLoader = new OBJLoader();
                objLoader.setMaterials(materials);
                objLoader.setPath('Assets/Aluminum_Bars/');

                objLoader.load('Aluminium_Ingot_Model.obj',
                    // onLoad callback
                    (object) => {
                        const model = object;
                        console.log(model);

                        this.objModel = object;
                        this.scene.add(object);

                        model.rotation.set(Math.PI / 2, -Math.PI / 2, Math.PI / 4);
                        // Asset loaded successfully
                        console.log("Asset loaded successfully!");
                    },
                    // onProgress callback
                    (xhr) => {
                        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    },
                    // onError callback
                    (error) => {
                        console.error("Error loading asset:", error);
                    }
                );

                this.addLighting();
            });
        } else if (shapeType === 'AlBar') {
            const objLoader = new OBJLoader();
            objLoader.setPath('Assets/Aluminum_Bars/');

            objLoader.load('Aluminium_Ingot_Model.obj',
                // onLoad callback
                (object) => {
                    const model = object;
                    console.log(model);

                    // Apply textures manually
                    object.traverse((child) => {
                        if (child instanceof THREE.Mesh) {
                            const textureLoader = new THREE.TextureLoader();
                            const texture = textureLoader.load('Assets/Aluminum_Bars/METAL BUMP-BIG.tif');
                            child.material.map = texture;
                        }
                    });

                    this.objModel = object;
                    this.scene.add(object);

                    model.rotation.set(Math.PI / 2, -Math.PI / 2, Math.PI / 4);
                    // Asset loaded successfully
                    console.log("Asset loaded successfully!");
                },
                // onProgress callback
                (xhr) => {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                // onError callback
                (error) => {
                    console.error("Error loading asset:", error);
                }
            );

            this.addLighting();
            


        } else if (shapeType === 'AlBarFBX') {
            const loader = new FBXLoader();

            loader.load('AssetsAlBarGltf/Aluminium_Ingot_Model.fbx', (fbx) => {
                // Add the loaded FBX model to the scene
                this.scene.add(fbx);

                // Handle textures
                fbx.traverse(child => {
                    if (child.isMesh) {
                        // Enable material to receive shadows and be affected by lights
                        child.castShadow = true;
                        child.receiveShadow = true;

                        // You can configure materials and textures here if needed
                        // For example:
                        // const material = new THREE.MeshStandardMaterial({ map: child.material.map });
                        // child.material = material;
                    }
                });

                // Position the camera to view the loaded model
                this.camera.position.set(0, 1, 5);
            });

            // Light setup (assuming you already have a light setup)
            const light = new THREE.DirectionalLight(0xffffff, 1);
            light.position.set(0, 10, 5);
            this.scene.add(light);

        } else if (shapeType === 'ALBARGLTF') {
            const loader = new GLTFLoader();

            loader.load('Assets/AlBarGltf',
                // onLoad callback
                (gltf) => {
                    const model = gltf.scene;
                    console.log(model);

                    this.gltfScene = gltf.scene;
                    this.scene.add(gltf.scene);

                    model.rotation.set(Math.PI / 2, -Math.PI / 2, Math.PI / 4);
                    // Asset loaded successfully
                    console.log("Asset loaded successfully!");
                },
                // onProgress callback
                (xhr) => {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                // onError callback
                (error) => {
                    console.error("Error loading asset:", error);
                }
            );

            this.addLighting();
        }else {
            throw new Error('Invalid shape type');
        }



        if (shapeType !== 'mobiusStrip' && shapeType !== 'Metalbox' && shapeType != 'MetalSphere' && shapeType != 'Goldbar') {

            this.shape = new THREE.Mesh(geometry, material);
        }


        if (shapeType !== 'Metalbox' && shapeType != 'MetalSphere' && shapeType != 'Goldbar') {

            this.shape.position.copy(startingPosition); // Set the starting position
            //this.shape.rotation.copy(startingRotation_);
            //startingRotation.x
            //const startingRotation_ = new THREE.Euler(Math.PI / startingRotation.x, startingRotation.y, startingRotation.z);


            this.shape.rotation.x = startingRotation.x;
            this.shape.rotation.y = startingRotation.y;
            this.shape.rotation.z = startingRotation.z;

            this.scene.add(this.shape);
        }


        if (showWireframe) {
            const edges = new THREE.EdgesGeometry(geometry);
            const wireframe = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 'white' }));
            this.shape.add(wireframe);
        }

        const animate = () => {
            requestAnimationFrame(animate);
            this.renderer.render(this.scene, this.camera);
        };

        animate();

        if (liveControl) {
            const controls = new OrbitControls(this.camera, canvasRef);
            controls.update();
        }


        return this.shape;
    }


}

export default ThreeJSApp
