const NavBarData = [
  
  {
    mainTitle: 'Products',
    link: '/products',
    key: 'Products',
    data: [
      { title: 'ADC 12 Aluminum Ingots', id: 'hardware-link', linkTo: 'productsAL' },
      { title: 'Copper Ingots', id: 'circuit-design-link', linkTo: 'productsCOP' },
    ]
  },
  {
    mainTitle: 'Purchasing',
    link: '/purchasing',
    key: 'Purchasing',
    data: [
      { title: 'Suppliers', id: 'matlab-link', linkTo: '/purchasing' },
    ]
  },
  {
    mainTitle: 'Operations',
    link: '/Operations',
    key: 'Operations',
    data: [
      { title: 'About', id: 'hardware-link', linkTo: '/Operations' },
    ]
  },
  
];

export default NavBarData


/*
{
    mainTitle: 'Contact',
    link: '/Contact',
    key: 'Contact',
    data: [
      { title: 'Reach Out', id: 'hardware-link', linkTo: '/Contact' },
    ]
  }
  */