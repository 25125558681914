import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import logo from '../../../imgs/people_outline_white_48dp.svg'
const Container = styled(motion.div)`
  width: auto;
  min-height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 18px;
  
  cursor: pointer;
  transition: color 0.5s ease;
`;
const Button = styled(motion.button)`
  background-color: transparent;
  font-family: "Urbanist", sans-serif;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 20px;
  padding: 0px 10px;
  min-height: 100%;
  height: 100%;
  width: auto;
  border-radius: 5vw;
  cursor: pointer;
`;


const Dropdown = styled(motion.div)`
  position: fixed;
  font-family: "Urbanist", sans-serif;
  top: ${({ navbarHeight }) => navbarHeight};
  background-color: #212529;
  height: auto;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid white;
`;

const OptionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Avatar = styled.div`
  width: auto;
  max-height: 100%;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
`;
const LoadedImage = styled(motion.img)`
    width: auto;
    max-height: 20px; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;
const LoginButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Button isOpen={isOpen} onClick={toggleDropdown}
        initial={{ scale: 1 }} // Initial scale when the component renders
        whileTap={{ scale: 0.7 }}>
        <LoadedImage src={logo} alt="" />
      </Button>
      {isOpen && (
        <Dropdown
          navbarHeight={props.navbarHeight}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <OptionItem>Login</OptionItem>
          <OptionItem>Create Account</OptionItem>
        </Dropdown>
      )}
    </Container>
  );
};

export default LoginButton;
