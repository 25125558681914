import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { images } from "./image-data";
import './styles.css';
import styled from 'styled-components';

const Container = styled(motion.div)`
  display: flex;
  position: relative;
  font-family: "Urbanist", sans-serif;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const CaroImage = styled(motion.img)`
  position: absolute;
  width: auto;
  height: 100%;
`;

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

function TimerWithCallback(duration, callback) {
  console.log("Start Timer", duration, callback);
  return setTimeout(callback, duration);
}

const Carousel = (props) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, props.images.length, page);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalDuration = 5000;

  const paginateTimer = (newDirection) => {
    setPage((prevPage) => {

      let updatedPage = 0;

      if(prevPage[0] + newDirection < 0){
        updatedPage = props.images.length - 1;
        
      } else if (prevPage[0] + newDirection >= props.images.length) {
        updatedPage = 0;

      } else {
        updatedPage = prevPage[0] + newDirection;
      }
      
      return [updatedPage, newDirection];
    });
  };

  const paginate = (newDirection) => {
    let updatedPage = 0;

      if(page + newDirection < 0){
        updatedPage = props.images.length - 1;
        
      } else if (page + newDirection >= props.images.length) {
        updatedPage = 0;

      } else {
        updatedPage = page + newDirection;
      }


    setPage([updatedPage, newDirection]);
  };

  const goToNextImage = useCallback(() => {
    paginateTimer(1);
  }, []);

  const autoChangeImage = () => {
    goToNextImage();
    // Restart the timer after the image changes
    setTimer(intervalDuration);
  };

  const [timer, setTimer] = useState(intervalDuration);
  const timerRef = useRef(null);

  function callback() {
    console.log("Timer Finished");
    autoChangeImage();
  }

  useEffect(() => {
    // Clear the previous timer when the page changes
    console.log("PAge",page)

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    // Start a new timer when the page changes
    timerRef.current = TimerWithCallback(intervalDuration, callback);
  }, [page]);

  useEffect(() => {
    // Reset the timer when the component mounts
    setTimer(intervalDuration);

    // Clear the timer when the user interacts with the carousel
    const clearTimer = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };

    // Add event listeners for interactions (you can customize these events)
    document.addEventListener("mousedown", clearTimer);
    document.addEventListener("touchstart", clearTimer);
    document.addEventListener("keydown", clearTimer);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener("mousedown", clearTimer);
      document.removeEventListener("touchstart", clearTimer);
      document.removeEventListener("keydown", clearTimer);
    };
  }, []);

  return (
    <Container>
      <AnimatePresence initial={false} custom={direction}>
        <CaroImage
          key={page}
          src={props.images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (swipe > swipeConfidenceThreshold) {
              setCurrentImageIndex((prevIndex) =>
                prevIndex === 0 ? props.images.length - 1 : prevIndex - 1
              );
              paginate(-1);
            } else if (swipe < -swipeConfidenceThreshold) {
              setCurrentImageIndex((prevIndex) =>
                prevIndex === props.images.length - 1 ? 0 : prevIndex + 1
              );
              goToNextImage();
              paginate(1);
            }
            // Reset the timer on user interaction
            setTimer(intervalDuration);
          }}
        />
      </AnimatePresence>
    </Container>
  );
};

export default Carousel;


/*<div className="next" onClick={() => paginate(1)}>
        {"‣"}
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        {"‣"}
      </div>*/