import React, { useEffect, useState } from "react";

export const useFpsCounter = () => {
  const [fps, setFps] = useState(0);

  useEffect(() => {
    let animationFrameId;
    let startTime = performance.now();
    let frames = 0;

    const updateFps = () => {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;
      frames++;

      if (elapsed > 1000) {
        setFps(Math.round((frames * 1000) / elapsed));
        frames = 0;
        startTime = currentTime;
      }

      animationFrameId = requestAnimationFrame(updateFps);
    };

    animationFrameId = requestAnimationFrame(updateFps);

    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  return fps;
};

const FpsCounter = () => {
  const fps = useFpsCounter();

  const fpsStyle = {
    position: "fixed",
    top: "10px",
    right: "10px",
    background: "rgba(0, 0, 0, 0.7)",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "5px solid white",
    fontSize: "12px",
    zIndex: "9999"
  };

  return <div style={fpsStyle}>{fps} FPS</div>;
};

export default FpsCounter ;
