//import Logo from '../../imgs/NCU-Logo-Transparent.png'
import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import Logo from '../Icons/Logo';
import NavBarData from '../../data/PageData/NavBar_Data'
import NavbarEXHeader from './Navbar_Expanded/NavbarEXHeader';
import styled from "styled-components";
import LanguageSelector from '../utility/Language/LanguageSelector';

import BasicDropdown from './Navbar_Expanded/BasicDropDown';
import BasicDropdownMobile from './Navbar_Mobile/BasicDropDownMobile';
import NavbarEXSub from './Navbar_Expanded/NavbarEXSub';

import DropDownStagger from './Navbar_Expanded/DropDownStagger';
import { motion, AnimatePresence, useCycle } from "framer-motion";
import ThreeJSApp from './../utility/Animations/ThreeJS/TJS_Utilities'
import { MenuToggle } from './Navbar_Mobile/MenuToggle';
import NavbarNEXHeader from './Navbar_Mobile/NavbarNEXHeader';
import DropDownMenu from './Navbar_Expanded/DropDownMenu/DropDownMenu';
import BasicDropdown_KeyFrames from './Navbar_Expanded/BasicDropDown_KeyFrames';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from '../Pages/HomePage';
import Products from '../Pages/Products';

import Products_AL from '../Pages/Products_AL'
import Products_COP from '../Pages/Products_COP'

import Purchasing from '../Pages/Purchasing';
import Operations from '../Pages/Operations';
import Contact from '../Pages/Contact';

import { Link, NavLink, useLocation } from 'react-router-dom';
import LoginButton from '../utility/Login/Login';


import Footer from './../Footer/Footer';

//border-bottom: 1px solid white;
///////////////////////////////////STYLED COMPONENTS/////////////////////////////////////////////
const Bar = styled('div')`
  /* Your existing styles for the button */
  position: fixed;
  z-index: 1000;
  top: 0;
  height: 5vh;
  display: flex;
  flex-direction: row;
  width: inherit;
  justify-content: space-between;
  background-color:  transparent;
  margin-right: 5vw;
  border-left: none;
  border-right: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const BarSideIcons = styled('div')`
  width: auto;
  display: flex;
  flex-direction: row;
  
  max-height: 100%;
  margin-right: 5vw;
`;


const BarM = styled('div')`
  /* Your existing styles for the button */
  height: 5vh;
  display: flex;
  flex-direction: row;
  width: inherit;
  justify-content: right;
`;

const BarBackground = styled('div')`
  /* Your existing styles for the button */
  position: fixed;
  height: 5vh;
  width: inherit;
  background-color: transparent;
`;

//background-color: #212529;
//background-color: RGB( 33, 37, 41, 0.9);
const BarWrapper = styled('div')`
  /* Your existing styles for the button */
  position: fixed;
  z-index: 1001;
  height: 5vh;
  width: inherit;
  backdrop-filter: blur(10px);
  background-color: RGB( 33, 37, 41, 0.9);
`;

const Container = styled(motion.div)`
  width: auto;
  min-height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 18px;
  
  cursor: pointer;
  transition: color 0.5s ease;
`;

const Navbar = (props) => {

  ///////////////////////////////////NAV LAYOUT/////////////////////////////////////////////
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);

  //Calculate Navbarheight
  useLayoutEffect(() => {
    updateNavHeight()
    function updateNavHeight() {
      // Get the height of the navbar element
      const height = window.getComputedStyle(navbarRef.current).height;

      if (height != navbarHeight) {
        setNavbarHeight(height);
      }

    }
    window.addEventListener('resize', updateNavHeight);
    window.addEventListener('orientationchange', updateNavHeight); // Listen for orientation change
    return () => {
      window.removeEventListener('resize', updateNavHeight);
      window.removeEventListener('orientationchange', updateNavHeight); // Clean up listener
    };
  }, []);


  ///////////////////////////////////DEVICE INFO/////////////////////////////////////////////
  // Use useEffect to update the state when NavBarData changes
  const [deviceInfoSimp, setDeviceInfoSimp] = useState({
    width: -1,
    height: -1,
    deviceType: -1,
    actualDeviceType: -1,
    fpsMode: -1,
    fpsTiming: -1
  })

  useEffect(() => {
    setDeviceInfoSimp(props.devInfoSimp)
  }, [props.devInfoSimp])


  ///////////////////////////////////DISPLAY MODE/////////////////////////////////////////////
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [currentHeaderArray, setCurrentHeaderArray] = useState([]);

  useEffect(() => {

    if (deviceInfoSimp != null) {
      const height = deviceInfoSimp['height'];
      if (deviceInfoSimp['deviceType'] != 'mobile') {
        const headerComponents = NavBarData.map((item, idx) => (
          <NavbarEXHeader
            key={"NavEXHEAD_" + idx}
            showDropdown={navbarToggle}
            currentState={curNavbarHeader}
            title={item.mainTitle}
            link={item.link}
            setState={handleCurrentHeader}
            state={idx}
          />
        ));
        setCurrentHeaderArray(headerComponents);
      } else {
        setCurrentHeaderArray([
          <Container
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}

          >
            <MenuToggle toggle={() => toggleOpen()} />
          </Container>
        ]);
      }
    }
  }, [deviceInfoSimp, isOpen])


  ///////////////////////////////////DATA HANDLING/////////////////////////////////////////////
  //Get data on the navbar header title
  const [titlesIndex, setTitlesIndex] = useState([]);
  const [titleArrays, setTitleArrays] = useState({});

  const [linkArrays, setLinkArrays] = useState([])

  const [TitlesElements, setTitlesElements] = useState({});
  const [dropDownElements, setDropDownElements] = useState();
  const [headerComponents, setHeaderComponents] = useState();


  useEffect(() => {
    const mainTitles = {};
    NavBarData.forEach((item, idx) => {
      const mainTitle = item.mainTitle;
      mainTitles[idx] = mainTitle;
    });
    setTitlesIndex(mainTitles);
  }, []);

  useEffect(() => {
    const newTitleArrays = {};
    const mainTitles = {};
    NavBarData.forEach((item, idx) => {
      const mainTitle = item.mainTitle;
      mainTitles[idx] = mainTitle;
      const titles = item.data.map(subItem => subItem.title);

      newTitleArrays[mainTitle] = titles;


    });
    setTitleArrays(newTitleArrays);
  }, [titlesIndex]);


  useEffect(() => {
    const newTitleArrays = {};
    const mainTitles = {};

    const LT = {};

    NavBarData.forEach((item, idx) => {
      const mainTitle = item.mainTitle;

      mainTitles[idx] = mainTitle;

      const titles = item.data.map(subItem => subItem.title);
      const links = item.data.map(subItem => subItem.linkTo);
      //console.log(titles, links)

      titles.forEach((title, index) => {
        LT[title] =links[index];
      })

      newTitleArrays[mainTitle] = LT;


    });
    setLinkArrays(newTitleArrays);
  }, [titleArrays]);

  useEffect(() => {
    Object.keys(titleArrays).forEach(mainTitle => {
      const titles = titleArrays[mainTitle];
      const titlesContent = titles.map(title => (
        <NavbarEXSub key={title} title={title} link={linkArrays[mainTitle][title]} />
      ));

      // Add NavBarExHeader with the title "Back" to titlesContent
      titlesContent.unshift(<NavbarEXSub key="back" title="Back" setState={handleCurrentHeaderSub} state={-1} />);

      setTitlesElements(prevTitlesContent => ({
        ...prevTitlesContent,
        [mainTitle]: titlesContent,
      }));
    });
  }, [linkArrays])



  // Initialize state to hold drop-down elements
  const [dropDownElementsArray, setDropDownElementsArray] = useState({});
  const [dropDownHeights, setDropDownElementsHeights] = useState([0, 0, 0, 0, 0])


  useEffect(() => {

    let headerComponents = NavBarData.map((item, idx) => (
      <NavbarNEXHeader
        key={"NavEXHEAD_" + idx}
        showDropdown={navbarToggle}
        title={item.mainTitle}
        link={item.link}
        setState={handleCurrentHeaderSub}
        state={idx + 0.5}
      />
    ));



    setHeaderComponents(headerComponents)
  }, [TitlesElements]);

  function HandleDropDownHeight(ddHeight, idx) {
    // Update the state with the new height for the specific index
    let temp = dropDownHeights
    //console.log(ddHeight, idx)

    temp[idx] = ddHeight
    setDropDownElementsHeights(temp)

  }

  const [dropdownListHeight, setDropdownListHeight] = useState([]);


  useEffect(() => {
    //console.log("DDheight Nav: ", dropDownHeights)
  }, [dropDownHeights])




  ///////////////////////////////////USER MOVEMENT/////////////////////////////////////////////
  //Handle Navbar interaction
  const [withinNav, setWithinNav] = useState(false)
  const [withinHeader, setWithinHeader] = useState(false);
  const [navbarToggle, setNavbarToggle] = useState(false)

  const [curNavbarHeader, setcurNavbarHeader] = useState(null)
  const [curNavbarSubHeader, setcurNavbarSubHeader] = useState(null)

  function handleCurrentHeader(val) {
    if (val != null) {
      setcurNavbarHeader(val)
    }
  }

  function handleCurrentHeaderSub(val) {
    if (val != null && val >= 0) {
      //console.log(val)
      setcurNavbarSubHeader(val)
    } else if (val < 0) {
      setcurNavbarSubHeader(null)
    }
  }

  function handleNavbarEnter() {
    setWithinNav(true)
  }

  function handleNavbarLeave() {
    setWithinNav(false)
  }

  useEffect(() => {
    //console.log(curNavbarHeader)
    if (curNavbarHeader != null) {
      setWithinHeader(true)
    } else {
      setWithinHeader(false)
    }
  }, [curNavbarHeader]);

  useEffect(() => {
    //console.log(curNavbarSubHeader)
    if (curNavbarHeader != null) {
      setWithinHeader(true)
    } else {
      setWithinHeader(false)
    }
  }, [curNavbarSubHeader]);

  useEffect(() => {
    if (deviceInfoSimp['deviceType'] != 'mobile') {
      if (withinNav) {
        if (withinHeader) {
          setNavbarToggle(true)
        }
      } else {
        setNavbarToggle(false)
        setcurNavbarHeader(null)
      }
    } else if (withinHeader) {
      setNavbarToggle(true)
      //console.log("here")
    }

  }, [withinNav, withinHeader]);

  //MenuToggle
  const containerRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      handleCurrentHeader(4);
    } else {
      setNavbarToggle(false)
      setcurNavbarHeader(null)
    }
  }, [isOpen])


  const [toggleSubMenu, setToggleSubMenu] = useState(navbarToggle)

  useEffect(() => {
    //console.log("Show Drop Down: ", navbarToggle)
    if (deviceInfoSimp['deviceType'] != 'mobile') {
      if (navbarToggle) {
        setToggleSubMenu(true)
      } else {
        setToggleSubMenu(false)
      }
    } else {
      if (navbarToggle) {
        setToggleSubMenu(true)
      } else {
        setToggleSubMenu(false)
      }
    }

  }, [navbarToggle]);

  useEffect(() => {
    if (!props.isLoaded) {
      if (dropDownElements != null) {
        setNavbarToggle(true)
      }
    }
  }, [dropDownElements]);


  /////////////////////////////////////WEBSITE LOADING CHECK/////////////////////////////////////////////

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (toggleSubMenu && !isLoaded) {
      //console.log("iloaded: ", isLoaded)
      props.handleDataLoad(true)
    }
  }, [toggleSubMenu]);

  useEffect(() => {
    if (props.isLoaded) {
      setIsLoaded(props.isLoaded)
    }
  }, [props.isLoaded, navbarToggle]);

  useEffect(() => {
    if (!props.isLoading) {
      //console.log("reset", props.isLoading)
      setNavbarToggle(false)
    }
  }, [props.isLoading]);

  ///////////////////////////////////DATA DISPLAY/////////////////////////////////////////////
  useEffect(() => {

    if (Object.keys(TitlesElements).length !== 0 && deviceInfoSimp['height'] !== -1 && currentHeaderArray.length > 0) {

      if (deviceInfoSimp['deviceType'] != 'mobile') {
        if (!curNavbarHeader && !props.isLoaded) {
          setDropDownElements(TitlesElements[titlesIndex[0]])
        } else {
          setDropDownElements(TitlesElements[titlesIndex[curNavbarHeader]]);
        }

      } else {

        setDropDownElements(headerComponents)
      }
    }
  }, [TitlesElements, curNavbarHeader, currentHeaderArray, deviceInfoSimp])

  const RedDivComponent = () => {
    return <div style={{ width: '100vw', height: '100vh', backgroundColor: 'red' }}></div>;
  };

  return (
    <>
      <BarWrapper onMouseEnter={handleNavbarEnter} onMouseLeave={handleNavbarLeave}>

        <Bar ref={navbarRef}>

          {deviceInfoSimp['deviceType'] != 'mobile' ? (

            <Bar>
              <Logo />

              {currentHeaderArray}

              <BarSideIcons>
                <LoginButton navbarHeight={navbarHeight}></LoginButton>
                <LanguageSelector navbarHeight={navbarHeight} />
              </BarSideIcons>

            </Bar>

          ) : (
            <>
              <Bar>
                <Logo />
                <BarM>

                </BarM>

                <BarSideIcons>
                  <LoginButton navbarHeight={navbarHeight}></LoginButton>
                  <LanguageSelector navbarHeight={navbarHeight} />
                  {currentHeaderArray}
                </BarSideIcons>

              </Bar>
            </>
          )}
        </Bar>

        <BarBackground />

        <BasicDropdown
          showDropdown={navbarToggle && (curNavbarHeader == 0 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[0]}
        ></BasicDropdown>
        <BasicDropdown
          showDropdown={navbarToggle && (curNavbarHeader == 1 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[1]}
        ></BasicDropdown>
        <BasicDropdown
          showDropdown={navbarToggle && (curNavbarHeader == 2 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[2]}
        ></BasicDropdown>
        <BasicDropdown
          showDropdown={navbarToggle && (curNavbarHeader == 3 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[3]}
        ></BasicDropdown>
        <BasicDropdown
          showDropdown={navbarToggle && (curNavbarHeader == 4 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[4]}
        ></BasicDropdown>

        <BasicDropdownMobile
          showDropdown={navbarToggle && (curNavbarSubHeader == 0.5 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[4]}
        ></BasicDropdownMobile>
        <BasicDropdownMobile
          showDropdown={navbarToggle && (curNavbarSubHeader == 1.5 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[4]}
        ></BasicDropdownMobile>
        <BasicDropdownMobile
          showDropdown={navbarToggle && (curNavbarSubHeader == 2.5 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[4]}
        ></BasicDropdownMobile>
        <BasicDropdownMobile
          showDropdown={navbarToggle && (curNavbarSubHeader == 3.5 || props.isLoading == true)}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          dropdownListHeight={dropDownHeights[4]}
        ></BasicDropdownMobile>

        <DropDownStagger
          key={'Products'} // Use a unique key for each component
          customKey={'Products'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarHeader == 0 || props.isLoading == true)}
          items={TitlesElements['Products']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'desktop'}
          sideWays={false}
          idx={0}
        />
        <DropDownStagger
          key={'Purchasing'} // Use a unique key for each component
          customKey={'Purchasing'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarHeader == 1 || props.isLoading == true)}
          items={TitlesElements['Purchasing']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'desktop'}
          sideWays={false}
          idx={1}
        />
        <DropDownStagger
          key={'Operations'} // Use a unique key for each component
          customKey={'Operations'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarHeader == 2 || props.isLoading == true)}
          items={TitlesElements['Operations']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'desktop'}
          sideWays={false}
          idx={2}
        />

        <DropDownStagger
          key={'Contact'} // Use a unique key for each component
          customKey={'Contact'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarHeader == 3 || props.isLoading == true)}
          items={TitlesElements['Contact']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'desktop'}
          sideWays={false}
          idx={3}
        />
        <DropDownStagger
          key={'Mobile'} // Use a unique key for each component
          customKey={'Mobile'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarHeader == 4 || props.isLoading == true) && curNavbarSubHeader == null}
          items={headerComponents}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'mobile'}
          sideWays={false}
          idx={4}
        />

        <DropDownStagger
          key={'ProductsMobile'} // Use a unique key for each component
          customKey={'ProductsMobile'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarSubHeader == 0.5 || props.isLoading == true)}
          items={TitlesElements['Products']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'mobile'}
          sideWays={true}
          idx={0.5}
        />
        <DropDownStagger
          key={'PurchasingMobile'} // Use a unique key for each component
          customKey={'PurchasingMobile'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarSubHeader == 1.5 || props.isLoading == true)}
          items={TitlesElements['Purchasing']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'mobile'}
          sideWays={true}
          idx={1.5}
        />
        <DropDownStagger
          key={'OperationstMobile'} // Use a unique key for each component
          customKey={'OperationsMobile'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarSubHeader == 2.5 || props.isLoading == true)}
          items={TitlesElements['Operations']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'mobile'}
          sideWays={true}
          idx={2.5}
        />
        <DropDownStagger
          key={'ContactMobile'} // Use a unique key for each component
          customKey={'ContactMobile'}
          setDDHeight={HandleDropDownHeight}
          toggleNavbar={toggleSubMenu && (curNavbarSubHeader == 3.5 || props.isLoading == true)}
          items={TitlesElements['Contact']}
          navbarHeight={navbarHeight}
          fpsTiming={deviceInfoSimp['fpsTiming']}
          deviceMode={'mobile'}
          sideWays={true}
          idx={3.5}
        />



      </BarWrapper>

      <Router style={{ zIndex: 0 }}>
        <Routes>
          <Route path='' element={<HomePage isLoading={props.isLoading} ></HomePage>} />
          <Route path='/home' element={<HomePage isLoading={props.isLoading} ></HomePage>} />

          <Route path='products' element={<Products></Products>} />
          <Route path='productsAL' element={<Products_AL></Products_AL>} />
          <Route path='productsCOP' element={<Products_COP></Products_COP>} />
          <Route path='/purchasing' element={<Purchasing></Purchasing>} />
          <Route path='Operations' element={<Operations></Operations>} />
          <Route path='Contact' element={<Contact></Contact>} />

        </Routes>
      </Router>

      


    </>
  );
};

export default Navbar;

/*

<NavbarNEXHeader />

{deviceInfoSimp['deviceType']}

GOLD BAR:
<div
        ref={canvasRef}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          backgroundColor: "translucent"
        }}
      />


{NavBarData[0]['data'].map((item, index) => (
            <NavbarEXSub key={index} item={item}>{item}</NavbarEXSub>
          ))}


{(fpsMode == FPS_MODE_0 || fpsMode == FPS_MODE_60 || fpsMode == FPS_MODE_120 || true) ? (

        {}


      ) : (
        <BasicDropdown_KeyFrames showDropdown={navbarToggle} navbarHeight={navbarHeight} fpsMode={fpsMode} />
      )}


*/