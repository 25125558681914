import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
const TextCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: auto;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

const TextCardB = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  margin-top: 2vh;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  
  
  width: 100%;
  height: auto;
  max-width: 100%; /* You can adjust the maximum width as needed */
  margin-bottom: 2vh;
`;

//font-family: 'Nunito Sans', sans-serif;
//font-family: poppins,Sans-serif;

const TextCardChild = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildE = styled.div`
  margin: auto;
  color: white;
  font-size: 1.6rem;
  width: auto;
  margin: 10px;
  padding-left: 10px;
  
  text-align: left;
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;

const TextCardChildI = styled.div`
  margin: 0;

  color: white;
  font-size: 1.6rem;

  height: 20vh;
  width: auto;
  
  margin: 0px;
  padding-left: 0px;
  
 
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: left;

  padding: 10px;
 
 
  
`;

const TextCardChildEI = styled.div`
  margin: 0;

  color: white;
  font-size: 1.6rem;

  height: 20vh;
  width: auto;
  
  margin: 0px;
  padding-left: 0px;
  
 
  border-right: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: right;

  padding: 10px;
 
 
  
`;


const TextCardChildC = styled.div`
  margin: 0;
  color: white;
  max-width: 30vw;
  margin-left: 20px;
  text-align: left;
`;

const TextCardChildD = styled.div`
  margin: 0;
  color: white;
  max-width: 50vw;
  margin-left: 20px;
  text-align: left;
`;


const TextCardChildA = styled.div`
  margin: 0;
  color: white;
  font-size: 5rem;
  margin: 20px;
  text-align: left;
    
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
`;

const TextCardChildB = styled.div`
  margin: 0;
  color: white;
  font-size: 1.5rem;
  width: auto;
  
  text-align: left;
`;


const LoadedImageB = styled(motion.img)`
    
    
    max-width: 100%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

const TextCardComponent = (props) => {
    return (
      <TextCard>
        <TextCardChildEI>
          <LoadedImageB src={props.img} />
        </TextCardChildEI>
        <TextCardChildA>
          {props.titles.map((title, index) => (
            <TextCardChildB key={index}>{title}</TextCardChildB>
          ))}
        </TextCardChildA>
      </TextCard>
    );
  };
export default TextCardComponent;