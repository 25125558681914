import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from 'react-router-dom';
import backIgm from './../../../imgs/arrow_forward_ios_white_48dp.svg';



const Container = styled(motion.div)`
  width: auto;
  height: auto;
  margin-left 1vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  align-items: center; /* Changed to center */
  height: 100%;
  
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  cursor: pointer;
  transition: 1s ease;
  
  z-index: 1010;
`;

const LinkS = styled("div")`
  text-decoration: none !important;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SvgWrapper = styled(motion.svg)` /* Changed to motion.svg */
  width: auto;
  height: 40px;
`;

const ArrowPath = styled(motion.path)` /* New styled component for the arrow */
  fill: none;
  stroke-width: 5;
  stroke: white;
`;

const ContainerArrow = styled(motion.div)`
 
`;

const LoadedImage = styled(motion.img)`
    width: auto;
    background-color: transparent;
    z-index: 1011;
    display: flex;
    max-height: 5vh; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
    display: 'flex;
    transform
    
`;

const LoadedImageRev = styled(motion.img)`
    width: auto;
    background-color: transparent;
    z-index: 1011;
    display: flex;
    max-height: 5vh; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
    display: 'flex;
    
`;


const BottomLine = styled(motion.div)`
z-index: 1011;
`;

export const NavbarNEXHeader = (props) => {
  const [fontColor, setFontColor] = useState("#CAD3D7");
  const [showArrow, setShowArrow] = useState(false);

  const handleHoverEnter = () => {
    setFontColor("black");
    setShowArrow(true);
  };

  const handleClickEnter = () => {
    props.setState(props.state);
  };

  const handleHoverLeave = () => {
    setFontColor("#CAD3D7");
    setShowArrow(false);
    props.setState(null);
  };

  useEffect(() => {
    //console.log(props.showDropdown)
  }, [props.showDropdown]);

  const svgPath = "M20 20 L80 50 L20 80 Z"; // Creates a ">" shape
  const svgPathReversed = "M80 20 L20 50 L80 80 Z";
  // Creates a ">" shape /* Changed to a simple ">" path */



  const animateVariantsLRArrow = {
    visible: {
      transform: ['translateX(100%)'],
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
    hidden: {
      transform: ['translateX(20%)'],
      opacity: 0.5,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };


  const animateVariantsLR = {
    visible: {
      
      scaleX: 1,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },

    },
    hidden: {
      
      scaleX: 0,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };

  const animateVariantsRLArrow = {
    visible: {
      x: "0%",
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
    hidden: {
      x: "100%",
      opacity: .5,
      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };




  const animateVariantsRL = {
    visible: {
      x: "0px",

      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
    hidden: {
      x: "100%",

      transition: {
        duration: 0.2, // Adjust the duration as needed
      },
    },
  };

  if (props.state > -5) {
    return (
      <Container
        style={{
          width: '100%',
          height: '6vh',
          backgroundColor: '#212529',

          

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          fontFamily: '"Urbanist", sans-serif',
          fontSize: '18px',
          cursor: 'pointer',

          zIndex: '1010',
        }
        }
        to={props.link}
        onMouseEnter={handleHoverEnter}
        onClick={handleClickEnter}
        onMouseLeave={handleHoverLeave}

        key={props.title + "HEADER"}
        layout

        whileHover={{


          color: "white",
          transition: { duration: 0.3 },
        }}
        animate={{
          color: fontColor,
        }}
        transition={{ duration: 0.4 }}
        whileTap={{ scale: 0.8 }}
      >
        {props.title}

        <LoadedImageRev
          layout
          src={backIgm}
          variants={animateVariantsLRArrow}
          animate={showArrow ? "visible" : "hidden"}
          alt="Example Image"
        />

        <BottomLine
          style={{
            transformOrigin: "left center",
            width: "100%",
            backgroundColor: "white",
            position: "fixed",
            bottom: "-1px",
            height: "4px",
          }}
          variants={animateVariantsLR}
          animate={showArrow ? "visible" : "hidden"}
        >

        </BottomLine>
      </Container >
    );
  } 

};

export default NavbarNEXHeader;
