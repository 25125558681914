import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import DeviceInfo from './../../utility/WindowSize/DeviceInfo'

const FPS_MODE_120 = 120
const FPS_MODE_60 = 60
const FPS_MODE_30 = 30
const FPS_MODE_0 = 0

const DropdownWrapper = styled.div`
  position: fixed;
  width: 100vw;
  z-index: 999;
`;

const TriggerButton = styled.button`
  padding: 10px;
  background: teal;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  width: 200px;
`;

const DropdownList = styled(motion.ul)`
  background: black;
  width: 100vw;
  color: #ffffff;
  list-style: none;
  margin: 0;
  font-size: 40px;
  overflow: hidden;
  padding: 0;
  overflow: auto;
  transform-origin: bottom; /* Set transform origin to bottom */
`;



const BasicDropdownMobile = (props) => {


    const dropdownListRef = useRef(null); // Create a ref for the DropdownList
    const [dropdownListHeight, setDropdownListHeight] = useState(66 * 9);

    /*
        useEffect(() => {
            if (dropdownListRef.current) {
                setDropdownListHeight(dropdownListRef.current.clientHeight); // Set the height when the component mounts
                ////console.log("dropDownHeight: ", dropdownListRef.current.clientHeight)
            }
        }, [dropdownListRef.current]);
    
        useEffect(() => {
            if (dropdownListRef.current) {
                setDropdownListHeight(dropdownListRef.current.clientHeight); // Set the height when the component mounts
                ////console.log("dropDownHeight 1: ", dropdownListRef.current.clientHeight)
            }
    
        }, []);
    
        */

    useEffect(() => {

        //console.log("DropDownHeight BAsic: ", props.dropdownListHeight)
        setDropdownListHeight(props.dropdownListHeight);
        if (dropdownListRef.current) {
            // Set the height when the component mounts
            ////console.log("dropDownHeight 1: ", dropdownListRef.current.clientHeight)

        }

    }, [props.dropdownListHeight]);


    const [duration, setDuration] = useState(DeviceInfo.FPS_MODE_0_T);

    useEffect(() => {
        let fpsTiming = props.fpsTiming
        if (fpsTiming > 0) {
            setDuration(fpsTiming)
        }
    }, [props.fpsTiming]);



    return (
        <DropdownWrapper >
            <AnimatePresence>
                {(props.showDropdown || (dropdownListHeight == 0)) && (
                    <DropdownList
                        ref={dropdownListRef}
                        style={{ transformOrigin: 'bottom', visibility: (dropdownListHeight == 0) ? "hidden" : "visible", height: dropdownListHeight }}
                        initial={{ opacity: 0, x: '100vw' }} /* Adjust y value to be -1 */
                        animate={{ opacity: 0.9, x: 0 }}
                        exit={{ opacity: 0, x: '100vw'}} /* Adjust y value to be -1 */
                        transition={{ duration: duration, ease: "easeInOut" }}
                    >
                        {true && (
                            <div
                                key={"SpacingBox"}
                                style={{ height: props.navbarHeight }}
                            ></div>
                        )}


                        { }

                    </DropdownList>
                )}
            </AnimatePresence>



        </DropdownWrapper>
    );
};

export default BasicDropdownMobile;

/* <DropDownStagger toggleNavbar = {toggleSubMenu } items = {props.items} ></DropDownStagger>
const DropdownItem = styled.li`
  margin-bottom: 2px;

  &:hover {
    font-size: 19px;
    cursor: pointer;
  }
`;
{items.map((item, index) => (
    <DropdownItem key={index}>{item}</DropdownItem>
))}
*/