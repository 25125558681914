import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ThreeJSApp from '../utility/Animations/ThreeJS/TJS_Utilities';
import Logo from '../Icons/LogoTitle';
import NM_DD from './../Navbar/Navbar_Mobile/NM_DD'

import AlImage from './../../imgs/WHAT-WE-SELL.jpg'
import CopperImage from './../../imgs/WHAT-WE-SELL-2.jpg'
import CloveImage from './../../imgs/WHAT-WE-SELL-3.jpg'

import SmelterImage from './../../imgs/SMELTER.jpg'
import Smelter2Image from './../../imgs/SMELTER-2.jpg'
import Smelter3Image from './../../imgs/SMELTER-5.jpg'

import background_1 from './../../imgs/background-white-pedestal_crop.jpeg'
import background_2 from './../../imgs/background_2_crop.jpeg'
import background_3 from './../../imgs/background_smelting.jpeg'
import background_4 from './../../imgs/background_dark.jpeg'



import Carousel from '../ImageCarousel/Carousel';
import SlideMenuCard from '../SlideDisplayCard/SlideDisplayCard';


import Footer from './../Footer/Footer';
// Styled Components

const TextCardWrapper = styled.div`
    



  padding-top: 0vh;

  width: 100%;
  height: auto;

  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  
  flex-direction: row;
  
 
  
`;

//background-image: url(${background_3}); 
const TextCardWrapperTop = styled.div`

background-image: url(${background_3}); 
background-size: cover; 

background-attachment: fixed;
@media only screen and (max-device-width: 1366px) {
  
    background-attachment: scroll;
 
}

  
  padding-top: 0vh;
  width: 100%;
  height: auto;

  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  
  flex-direction: row;
  
 
  
`;

//#040e3d background-color: #CAD3D7;
const Container = styled(motion.div)`

  display: flex;

  position: relative;
  font-family: "Urbanist", sans-serif;
  flex-direction: column;
  align-items: center;
  

  margin: auto;
  padding-top: 5vh;
  padding-bottom: 0vh;

  min-height: 100%;
  height: auto;
  max-height: 200%;


  width: 100%;
  max-width: 100%;

  
  z-index: 1;
  
`;

const Title = styled.div`
  max-width: 80%;
  height: auto;
    
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(2px); 

  font-size: 4rem;
  color:  white;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  
  border-bottom: 1px solid black;
`;

// CMRA

//backdrop-filter: blur(10px);
//background-image: url(${background_2});
const Card = styled.div`

font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: column;


align-items: left;
justify-content: auto;

padding-top: 2vh;


height: auto;

width: 100%;

color: white;



border-left: 0;

border-right: 0;




background-size: cover; 
background-repeat: no-repeat; 
background-position: center center;
`;

const ImageCard = styled.div`
position: relative;
z-index: 1;
font-family: "Urbanist", sans-serif;

display: flex;
flex-direction: auto;
align-items: center;
justify-content: space-evenly;

height: auto;
width: 100%;


background-color: RGB(255,255,255, 1);
backdrop-filter: blur(10px);

margin: 0;
padding-top: 10px;
padding-bottom: 10px;

overflow: hidden;
    
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #666;
`;

const Button = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
`;

const AnimatedDescription = styled(motion.p)`
  font-size: 100%;
  
  
  font-family: 'Nunito Sans', sans-serif;
  color: #36A423;
`;

const BlurredBackground = styled.div`
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
`;

// background-image: url(${background_1}); 
const TextCard = styled.div`

backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px); 
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: row;
  align-items: center;
  
  padding-top: 2vh;
  border-radius: 4px;
  padding-bottom: 2vh;
  
  width: 100%;
  max-width: 100%;
  min-width: 100%;

  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center center;

  
  height: auto;
   /* You can adjust the maximum width as needed */
  

`;



//background-image: url(${background_2}); 
//background-color: RGB( 202, 211, 215, 0.9);
//background-color: RGB( 33, 37, 41, 0.9);
const TextCardI2 = styled.div`

position: relative;
background-attachment: fixed;
background-size: cover;
background-repeat: no-repeat;

background-image: url(${background_3}); 


-webkit-background-size: cover;


@media only screen and (max-device-width: 1366px) {
  
    background-attachment: scroll;

}

display: flex;
flex-wrap: wrap;
justify-content: left;
flex-direction: column;
align-items: center;

padding-top: 2vh;
border-radius: 4px;
padding-bottom: 2vh;

width: 100%;
max-width: 90%;
min-width: 100vw;




height: auto;
`;

//font-family: 'Nunito Sans', sans-serif;
//font-family: poppins,Sans-serif;

const TextCardChild = styled.div`
  
  color: white;
  font-size: 160%;
  width: auto;
  max-width: 50%;
  min-width: 50%;
  padding-left: 2%;
  
  text-align: left;
  border-left: 3px solid white;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

  justify-content: start;
  flex-direction: column;
  
`;



const TextCardChildC = styled.div`
  margin: 0;
  color: white;
  
  max-width: 50vw;
  margin-left: 20px;
  text-align: left;
  
`;

const TextCardChildA = styled.div`
    padding-left: 5%;
    padding-right: 5%;
    color:  RGB( 33, 37, 41, 1);
  

  width: auto;
  max-width: 50%;
 
  
    

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  
`;


const TextCardChildB = styled.div`

  margin: 0;
  color:  white;
  font-size: 200%;
  width: auto;
  
  text-align: center;
  
`;

const TextCard_ = styled.div`

backdrop-filter: blur(2px);
-webkit-backdrop-filter: blur(2px); 
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: column;
  align-items: center;
  
  padding-top: 2vh;
  border-radius: 4px;
  padding-bottom: 2vh;
  
  width: 100%;
  max-width: 100vw;
  min-width: 100vw;

  
  
  height: auto;
   /* You can adjust the maximum width as needed */
  

`;

const TextCardChild_ = styled.div`
  
color:  RGB( 33, 37, 41, 1);
font-size: 2rem;
  width: auto;
  max-width: 100%;
  min-width: 50%;
  margin-left: 2%;
  padding-left: 2%;
  
  text-align: left;
 
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;

    
  
  flex-direction: row;
  justify-content: left;
  
`;

const TextCardChildAA = styled.div`
    
  color: white;
  

  width: 100%;
  max-width: 100%;
 
  margin: 0;
  padding-left: 2%;

  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: row;
  
`;

const TextCardChildBB = styled.div`

  margin: 0;
  padding-right: 1%;
  padding-bottom: 0.5%;
  margin-bottom: 1%;
  border-bottom: 3px solid white;
  color:  white;
  font-size: 3rem;
  width: auto;
  max-width: 33%;
  
  text-align: center;
  
`;

const TextCardChildCC = styled.div`
  
color: RGB( 202, 211, 215, 0.9);
  
  width: auto;
  max-width: 90%;
  
  text-align: left;
  
`;


/*
border-top: 1px solid #ccc;

  border-bottom: 1px solid #ccc;
  */


const LoadedImage = styled(motion.img)`
    
    
    max-width: 33%;
    height: auto; /* Set height to auto to maintain aspect ratio */
    object-fit: contain; /* Adjust object-fit property as needed */
`;

// Framer Motion variants
const containerVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.3 } },
};

const buttonVariants = {
    hover: { scale: 1.05 },
};

const HomePage = (props) => {


    useEffect(() => {
        // ...
    }, [props.isLoading]);

    const word = "Recycled ingot production at it's finest";

    const animateDescription = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
    };

    const highlightVariants = {
        hidden: { opacity: 1, color: 'Black' },
        visible: { opacity: 1, color: '#36A423' },
    };


    const images = [
        SmelterImage,
        Smelter2Image,
        Smelter3Image,
        // Add more image URLs here
    ];

    const ncuGroupInfo = `
    NCU Group has over 20 years of expertise in working with non-ferrous metal scrap.
    Our team comprises senior engineers with more than 30 technical and management staff members.
    We employ cutting-edge flotation technology for processing and screening, as well as smelting
     to produce pure non-ferrous metals suitable for secondary sales. We primarily import stainless
      steel and non-ferrous metal scraps from the US, Europe, Japan, and other locations. 
      Currently, we can smelt 6000 MT / month of the required ingot with specifications tailored to meet customer needs.
    `;

    //We primarily import stainless steel and non-ferrous metal scraps from the US, Europe, and Japan. Currently, per month we can produce 3000MT of aluminium, and 2000MT of copper.
    const ncuGroupTitle = `
    Crafting. Precision. Excellence.
    `;

    const words = ncuGroupInfo.split(/\s+/);

    // Group words into arrays of 5 words each
    const groupedWords = [];
    for (let i = 0; i < words.length; i += 6) {
        groupedWords.push(words.slice(i, i + 5).join(' '));
    }

    return (
        <Container variants={containerVariants} initial="initial" animate={!props.isLoading ? "animate" : "initial"}>

            <TextCardWrapperTop>
                <TextCard>
                    <TextCardChildA >
                        <TextCardChildB>
                            Sustainable
                        </TextCardChildB>
                        <TextCardChildB>
                            ingots,
                        </TextCardChildB>
                        <TextCardChildB>
                            greener
                        </TextCardChildB>
                        <TextCardChildB>
                            future
                        </TextCardChildB>
                    </TextCardChildA >
                    <TextCardChild >

                        <AnimatedDescription variants={animateDescription}>
                            {word.split('').map((letter, index) => (
                                <motion.span
                                    key={index}
                                    variants={highlightVariants}
                                    initial="hidden"
                                    animate={!props.isLoading ? "visible" : "hidden"}

                                    transition={{ duration: 0.01, delay: 0.07 * index, start: 1 }}
                                >
                                    {letter}
                                </motion.span>
                            ))}

                        </AnimatedDescription>


                    </TextCardChild >

                </TextCard>
            </TextCardWrapperTop>

            <TextCardWrapper>
                <TextCard_>
                    <TextCardChildAA >
                        <TextCardChildBB>
                            Overview
                        </TextCardChildBB>

                    </TextCardChildAA >
                    <TextCardChild_ >

                        <TextCardChildCC>{ncuGroupInfo}</TextCardChildCC>


                    </TextCardChild_ >

                </TextCard_>
            </TextCardWrapper>

            <TextCardI2>
                <Title> Products</Title>
                <SlideMenuCard>

                </SlideMenuCard>
            </TextCardI2>

            <Footer></Footer>







        </Container>
    );


};




export default HomePage;

/*
<TextCard>
                <Carousel images={images}>
                </Carousel>
            </TextCard>


 <Button variants={buttonVariants} whileHover="hover">
                Get Started
            </Button>
            */

/*

<ImageCard>
    <LoadedImage src={SmelterImage} alt="" />
    <LoadedImage src={Smelter2Image} alt="" />
    <LoadedImage src={Smelter3Image} alt="" />


</ImageCard>





<ImageCard>
    <LoadedImage src={AlImage} alt="" />
    <LoadedImage src={CopperImage} alt="" />
    <LoadedImage src={CloveImage} alt="" />


</ImageCard>

*/