
import React, { useLayoutEffect, useState, useEffect, useRef, us } from 'react';
import logo from './logo.svg';


import FpsCounter, { useFpsCounter } from './components/utility/FPS/FpsCounter';
import DeviceInfo, { DeviceInfoDisplay } from './components/utility/WindowSize/DeviceInfo'

import './App.css';
import Navbar from './components/Navbar/NavBar';
import Footer from './components/Footer/Footer';


import LoadingScreen from './components/utility/LoadingScreen/LoadingScreen';
function App() {
  
  const fps = useFpsCounter();
  const [deviceInfo, setDeviceInfo] = useState(null)
  const [deviceInfoSimp, setDeviceInfoSimp] = useState({
    width: -1,
    height: -1,
    deviceType: -1,
    actualDeviceType: -1,
    fpsMode: -1,
    fpsTiming: -1
  })

  const [deviceTiming, setDeviceTiming] = useState(10)

  const [deviceIP, setdeviceIP] = useState(null)
  
  const [isLoading, setIsLoading] = useState(true) //True -> run false-> off
  const [isLoaded, setIsLoaded] = useState(false); //False -> run true : off

  
  //Here I am going to determine fps mode and device
  useEffect(() => {
    if (deviceInfo == null) {
      const devInfo = new DeviceInfo(handleDeviceInfo, handleIPAddress)
      setDeviceInfo(devInfo);
    }

  }, []);

  useEffect(() => {
    if (deviceInfo) {
      let fpsMode = deviceInfo.fpsMode
      if ((fps > fpsMode + 10) || (fps < fpsMode - 10)) {
        deviceInfo.setFPSMode(fps)
      }
      handleDeviceLoad(true)
    }

    

  }, [fps, deviceInfo])

  //might be running a lot
  function handleDeviceInfo(dInfo) {
    if (dInfo && Object.values(dInfo).every(value => value !== null && value !== 0)) {
      setDeviceInfoSimp(dInfo);
    } else {
      //console.error("Invalid device info:", dInfo);
    }
  }

  function handleIPAddress(ip) {
    if (ip) {
      setdeviceIP(ip)
    }
  }
  
  const [dataLoaded, setDataLoaded] = useState(false)
  const [deviceLoaded, setDeviceLoaded] = useState(false)

  function handleDataLoad (val){
    //console.log("Data Loaded:", val)
    setDataLoaded(val)
  }
  function handleDeviceLoad (val){
    //console.log("Device Loaded:", val)
    setDeviceLoaded(val)
  }
  function handleLoaded (){
    
    setIsLoading(false);
  }

  useEffect(() => {
    // Simulate an asynchronous process (e.g., fetching data) with a setTimeout

    /*
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000); // Set the loading time here (in milliseconds)*/

    if(dataLoaded == true && deviceLoaded == true){
      setIsLoaded(true);
    }

    //console.log("Loaded:", dataLoaded == true, deviceLoaded == true)
    
    
  }, [dataLoaded, deviceLoaded]);

  useEffect(() => {
    if(!isLoading){
      console.log("HERER")
    }
    
  }, [isLoading]);


  

  return (
    <div className="App" >
      
      <LoadingScreen isLoading={isLoading} isLoaded={isLoaded} handleLoaded = {handleLoaded}/>

      <Navbar devInfoSimp={deviceInfoSimp} devInfo={deviceInfo} handleDataLoad={handleDataLoad} isLoaded={isLoaded} isLoading={isLoading}></Navbar>

      <header className="App-header">
        
      </header>

      


    </div>
  );
}

//background-image: url(./WechatIMG1924.jpeg); 

export default App;
//<DeviceInfoDisplay width={deviceInfoSimp.width} height={deviceInfoSimp.height} deviceType={deviceInfoSimp.deviceType} actualDeviceType={deviceInfoSimp.actualDeviceType} ipAddress={deviceInfoSimp.ipAddress} />

/*
<img src={logo} className="App-logo" alt="logo" />
        
        <DeviceInfoDisplay
            width={deviceInfoSimp['width']}
            height={deviceInfoSimp['height']}
            deviceType={deviceInfoSimp['deviceType']}
            actualDeviceType={deviceInfoSimp['actualDeviceType']}
            fpsMode={deviceInfoSimp['fpsMode']}
            fpsTiming={deviceInfoSimp['fpsTiming']}
            ipAddress={deviceIP}
          />



/*useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('touchmove', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('touchmove', handleResize);
    };
  }, []);
  */