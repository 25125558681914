import * as React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const Path = props => (
    <PathEl
        fill="#CAD3D7"
        strokeWidth="3"
        stroke="white"
        strokeLinecap="round"
        justifyContent="center"
        alignItems="center"
        {...props}
        
    />
);

const PathEl = styled(motion.path)`
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 33%;
`;


const Button = styled.button`
  background-color: transparent;
  font-family: "Urbanist", sans-serif;
  color: black;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 20px;
  padding: 0px 10px;
  max-height: 100%;
  
  width: auto;
  border-radius: 5vw;
  cursor: pointer;
`;

const SVGCONT = styled.svg`
    min-width: 25px;
    height: auto;
    objectFit: contain;
    


    padding-top: 4px;
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    alignItems: center;
`;



export const MenuToggle = ({ toggle }) => (

    <Button onClick={toggle}>
        <SVGCONT viewBox="0 0 23 23"   >
            <Path
            
                variants={{
                    closed: { d: "M 2 2.5 L 20 2.5" },
                    open: { d: "M 3 16.5 L 17 2.5", stroke: "white" }
                }}
            />
            <Path
                d="M 2 9.423 L 20 9.423"
                variants={{
                    closed: { opacity: 1 },
                    open: { opacity: 0 }
                }}
                transition={{ duration: 0.1 }}
            />
            <Path
                variants={{
                    closed: { d: "M 2 16.346 L 20 16.346" },
                    open: { d: "M 3 2.5 L 17 16.346", stroke: "white" }
                }}
            />
        </SVGCONT>
    </Button>
);

export default MenuToggle